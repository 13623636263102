import * as React from "react";
import Svg from "../../components/svg";

import FooterLogoIcon from "../../assets/footer-logo.svg";
import FooterStoreImg from "../../assets/footer-store.png";
import FooterTradeIcon from "../../assets/footer-trade.svg";

export type FooterProps = {
  //
};

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="footer">
      <div className="footer__copy-right">
        <div className="footer__copy-right__logo">
          <img src={FooterLogoIcon} width={127} height={52} alt="clinio-footer" />
        </div>
        <div className="footer__copy-right__header">التطبيق متاح على</div>

        <div className="footer__copy-right__store">
          <img src={FooterStoreImg} width={228} height={37} alt="clinio-store" />
        </div>
        <div className="footer__copy-right__trade-mark">
          <Svg icon={FooterTradeIcon} color="var(--color-gray-8)" width="34.1rem" height="1.6rem" />
        </div>
      </div>
      <div className="footer__links">
        <div className="footer__links__column">
          <div className="footer__links__column__header">كلينكو</div>
          <div className="footer__links__column__list">
            <div className="footer__links__column__list__item">من نحن</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
          </div>
        </div>
        <div className="footer__links__column">
          <div className="footer__links__column__header">كلينكو</div>
          <div className="footer__links__column__list">
            <div className="footer__links__column__list__item">من نحن</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
          </div>
        </div>
        <div className="footer__links__column">
          <div className="footer__links__column__header">كلينكو</div>
          <div className="footer__links__column__list">
            <div className="footer__links__column__list__item">من نحن</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
          </div>
        </div>
        <div className="footer__links__column">
          <div className="footer__links__column__header">كلينكو</div>
          <div className="footer__links__column__list">
            <div className="footer__links__column__list__item">من نحن</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
            <div className="footer__links__column__list__item">فريق كلينيو</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
