import * as React from "react";
import Button from "../button";
import ArrowIcon from "../../assets/arrow-left.svg";
export type CarouselProps = {
  //
  images: string[];
};

const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const [index, setIndex] = React.useState<number>(0);
  // const scrollableELRef = React.useRef<HTMLDivElement | null>(null);

  const _renderItems = React.useMemo(() => {
    return props.images.map((img, i) => (
      <div
        className={`carousel__show__item`}
        style={{ display: i >= index && i < index + 3 ? "block" : "none" }}
        key={i}
      >
        <img src={img} alt="" />
      </div>
    ));
  }, [index]);

  const preAction = () => {
    if (!(index - 1 < 0)) {
      setIndex(index - 1);
    }

    // scrollableELRef.current?.scrollBy({ behavior: "smooth", left: -209 });
  };
  const nextAction = () => {
    if (index + 3 < props.images.length) {
      setIndex(index + 1);
    }
    // scrollableELRef.current?.scrollBy({ behavior: "smooth", left: 209 });
  };

  return (
    <div className="carousel">
      <div className="carousel__prev">
        <Button.ButtonRounded
          svg={{
            color: "var(--color-gray-8)",
            width: "0.5rem",
            height: "0.9rem",
            reversable: true,
            icon: ArrowIcon
          }}
          action={preAction}
          classes={`button button--rounded button--primary ${!(index - 1 < 0) ? "" : "disabled"}`}
        />
      </div>
      <div
        className="carousel__show"
        // ref={scrollableELRef}
      >
        {_renderItems}
      </div>
      {/* </div> */}
      <div className="carousel__next">
        <Button.ButtonRounded
          svg={{
            color: "var(--color-gray-8)",
            width: "0.5rem",
            height: "0.9rem",
            reversable: true,
            icon: ArrowIcon
          }}
          action={nextAction}
          classes={`button button--rounded button--primary  ${
            index + 3 < props.images.length ? "" : "disabled"
          }`}
        />
      </div>
    </div>
  );
};

export default Carousel;
