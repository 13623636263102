import { AxiosResponse } from "axios";

import { axios } from "./configuration";

export interface BookingBody {
  patient: string;
  doctor: string;
  slot_time: number;
  patients: number;
  bookingsetup: string;
  BBTflag: boolean;
  time_end: string;
  session: {
    from: string;
    session_no: string;
  };
}

export interface Booking {
  session: {
    from: string;
    session_no: string;
  };
  online_flag: false;
  followUp_flag: string;
  status_patient: string;
  status: true;
  _id: string;
  doctor: string;
  patient: string;
  clinic: string;
  staff: string;
  booking_Setup: string;
  createdAt: string;
  updatedAt: string;
}

export interface BookingResponse {
  name: {
    first: { [key: string]: string };
    father: { [key: string]: string };
    grand: { [key: string]: string };
    family: { [key: string]: string };
  };
  lang: [];
  allergy_id: [];
  medical_report: [];
  Role: string;
  _id: string;
  dob: string;
  email: string;
  gender: string;
  mobileCode: string;
  mobile_no: string;
  sys_country_code3: string;
  createdAt: string;
  clinics: [
    {
      _id: string;
      clinic: string;
      date: string;
    }
  ];
  insurance_id: [];
  sick_leave: [];
  patient_admission: [];
  patient_images: [];
  updatedAt: string;
  bookings: Booking[];
}

export const BookingApi = {
  book: async function (
    bookingBody: BookingBody,
    token?: string
  ): Promise<AxiosResponse<BookingResponse>> {
    return axios.post(`/bookingByPatient`, bookingBody, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};
