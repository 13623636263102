import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSpecialitiesDispatch } from "../../redux/middlewares/specialityMiddlewares";

import Button from "../../components/button";
import List from "../../components/list";
import Input from "../../components/input";
import SelectList from "../../components/selectlist";
import CardStore from "../../components/cardStore";
import CopyRight from "../../components/copyRight";
import Svg from "../../components/svg";

import ArrowLeftIcon from "../../assets/arrow-left.svg";
import MedListIcon from "../../assets/med-list.svg";
import LocationIcon from "../../assets/location.svg";
import EarPlugIcon from "../../assets/ear-plug.svg";
import CallIcon from "../../assets/call.svg";
import LogoIcon from "../../assets/logo-2.svg";
import crossIcon from "../../assets/delete.svg";

import { useHome } from "./useHome";

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    form,
    resetSpecialties,
    resetLocation,
    onSwitchChange,
    onInputChange,
    onMultiSelectChange,
    specializeView,
    setSpecializeView,
    geographicView,
    setGeographicView,
    specialitiesData,
    regionData,
    areaData,
    areasPage,
    specialitiesPage,
    setAreasPage,
    setSpecialitesPage,
    selectedRegion,
    selectedAreas,
    selectedSpecialities,
    translate
  } = useHome();

  React.useEffect(() => {
    dispatch(getSpecialitiesDispatch());
  }, [dispatch]);

  console.log("specialitiesData:", specialitiesData);

  return (
    <React.Fragment>
      <div className="home mirror ">
        <div className="home__logo mirror">
          <img src={LogoIcon} alt="Clinio" />
        </div>
        <div className="home__container mirror">
          <div className="home__form-container">
            <div className="home__form">
              <div className="form">
                <div className="form__search">
                  <div className="form__search__header">
                    {translate("home__search__main_header__title")}
                  </div>
                  <div className="form__search__sub-header">
                    {/* {translate("home__search__sub_header__title")} */}
                  </div>
                  <div className="form__search__content">
                    <div className="form__search__content__item">
                      <Input.InputNormal
                        name=""
                        onfocus={() => {
                          setSpecializeView(specializeView == "block" ? "none" : "block");
                          setGeographicView("none");
                        }}
                        value={selectedSpecialities?.map((s) => s.name ?? "").join(", ")}
                        pre={
                          <Input.InputPre
                            svg={{
                              color: "var(--color-gray-5)",
                              height: "2.4rem",
                              width: "2.4rem",
                              icon: MedListIcon
                            }}
                            text={translate("home__search__specialities")}
                          />
                        }
                        adornment={
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {selectedSpecialities?.length ? (
                              <Button.ButtonRounded
                                svg={{
                                  width: "2.4rem",
                                  height: "2.4rem",
                                  color: "var(--color-status-delete)",
                                  reversable: true,
                                  icon: crossIcon
                                }}
                                overrideStyles={{ background: "var(--color-gray-8)" }}
                                action={resetSpecialties}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home__footer">
              <CopyRight />
            </div>
          </div>
          <div
            className="home__card-store "
            style={{
              display: specializeView == "block" || geographicView == "block" ? "none" : "block"
            }}
          >
            <CardStore />
          </div>
        </div>
      </div>
      <div
        className="home__specialized"
        style={{
          display: specializeView
        }}
      >
        <div className="home__specialized__content">
          <div className="home__specialized__content__container">
            <SelectList
              onPageChange={setSpecialitesPage}
              page={specialitiesPage}
              name="specialityIds"
              onChange={onMultiSelectChange}
              selectedIds={selectedSpecialities?.map((s) => s._id ?? "")}
              columns={1}
              rows={10}
              header={translate("home__search__specialities__f_search_header")}
              data={specialitiesData}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
