import * as React from "react";

import { useTranslation } from "../../hooks";
import LogoIcon from "../../assets/logo-2.svg";
import OneOfIcon from "../../assets/one-of.svg";
const CopyRight: React.FC<any> = () => {
  const { translate } = useTranslation();
  return (
    <div className="copy-right">
      <div className="copy-right__main-phrase">
        <div className="copy-right__main-phrase__text">{translate("clinio__main_copy_rights")}</div>
        <div className="copy-right__main-phrase__icon">
          <img src={LogoIcon} alt="" width={37} height={15} />
        </div>
      </div>
      <div className="copy-right__sub-phrase">
        <div className="copy-right__sub-phrase__text">{translate("clinio__sub_copy_rights")}</div>
        <div className="copy-right__sub-phrase__icon">
          <img src={OneOfIcon} alt="" width={42} height={12} />
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
