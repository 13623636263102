import * as React from "react";
// import { useAppSelector } from "../../redux";
import GooglePlayImg from "../../assets/google-play.png";
import AppStoreImg from "../../assets/app-store.png";
import { useTranslation } from "../../hooks";

export type CardStoreProps = {
  //
};

const CardStore: React.FC<CardStoreProps> = () => {
  const { translate } = useTranslation();
  return (
    <div className="card-store">
      <div className="card-store__header">
        <div className="card-store__header__text">{translate("clinio__app_main_header")}</div>
        <div className="card-store__header__sub-text">{translate("clinio__app_sub_header")}</div>
      </div>

      <img src={GooglePlayImg} width={210} height={72} alt="googlePlay" />

      <img src={AppStoreImg} width={210} height={72} alt="AppStore" />
    </div>
  );
};

export default CardStore;
