import { AppDispatch } from "..";
import { RegionsApi } from "../../api/regionsApi";
import { setDoctorQuery } from "../actions/doctorActions";
import { setLoading } from "../actions/loadingActions";
import { resetSelectedRegion, setRegionData, setSelectedRegion } from "../actions/regionActions";

export const getRegionsDispatch = (countryId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ region: true }));
    const response = await RegionsApi.getAll(countryId).catch((r) => r);
    dispatch(setLoading({ region: false }));
    if (response.status != 200) {
      return;
    }
    dispatch(setRegionData(response.data.data ?? []));
  };
};

export const setSelectedRegionDispatch = (regionId?: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedRegion(regionId));
    dispatch(setDoctorQuery({ region: regionId }));
  };
};

export const resetSelectedRegionDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedRegion());
    dispatch(setDoctorQuery({ region: "" }));
  };
};
