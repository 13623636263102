import { RootLabelsAction, LabelsState } from "../../interfaces";
import { LabelsActionTypes } from "../../constants/actionTypes";

// Initial state
const labelsState: LabelsState = {
  data: {}
};

const LabelsReducer = (
  state: LabelsState = labelsState,
  { type, payload }: RootLabelsAction
): LabelsState => {
  switch (type) {
    case LabelsActionTypes.SET_LABELS_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.lang]: payload.data
        }
      };
    }
    default:
      return state;
  }
};

export default LabelsReducer;
