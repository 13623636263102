import { DoctorLevelActionTypes } from "../../constants/actionTypes";
import { DoctorLevel } from "../../interfaces";

export const setDoctorLevelData = (doctorLevelList: DoctorLevel[]) => {
  return {
    type: DoctorLevelActionTypes.SET_DOCTORLEVEL_DATA,
    payload: doctorLevelList
  };
};

export const setSelectedDoctorLevels = (doctorLevelIds?: string[]) => {
  return {
    type: DoctorLevelActionTypes.SET_SELECTED_DOCTORLEVELS,
    payload: doctorLevelIds
  };
};
