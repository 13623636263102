import { AppDispatch } from "..";
import { AreasApi } from "../../api/areasApi";
import { resetSelectedArea, setAreaData, setSelectedArea } from "../actions/areaActions";
import { setDoctorQuery } from "../actions/doctorActions";
import { setLoading } from "../actions/loadingActions";

export const getAreaDispatch = (regionId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ area: true }));
    const response = await AreasApi.getAll(regionId).catch((r) => r);
    dispatch(setLoading({ area: false }));
    if (response.status != 200) {
      return;
    }
    dispatch(setAreaData(response.data));
  };
};

export const setSelectedAreasDispatch = (areaIds?: string[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedArea(areaIds));
    dispatch(setDoctorQuery({ area: areaIds }));
  };
};

export const resetSelectedAreasDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedArea());
    dispatch(setDoctorQuery({ area: [] }));
  };
};
