import { AppDispatch } from "..";
import { DoctorsApi, ReviewResponse } from "../../api/doctorsApi";
import { doctorQuery } from "../../interfaces";
import {
  setDoctorData,
  setDoctorQuery,
  setDoctorSessions,
  setSelectedDoctor,
  setSelectedDoctorFromList,
  setDoctorReviews,
  updateDoctorReview
} from "../actions/doctorActions";
import { setLoading } from "../actions/loadingActions";

export const getDoctorsDispatch = (query: doctorQuery) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ doctors: true }));
    const response = await DoctorsApi.getAll(query);
    if (response.status != 200) {
      // dispatch(setDoctorQuery(query));
      return;
    }
    dispatch(
      setDoctorData(query.pageNumber ?? 0, response.data.total ?? 0, response.data.data ?? [])
    );
    dispatch(setLoading({ doctors: false }));
  };
};

export const getDoctorByIdDispatch = (doctorId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ doctor: [doctorId] }));
    const response = await DoctorsApi.getById(doctorId);
    if (response.status != 200) {
      return;
    }
    dispatch(setSelectedDoctor(response.data));
    dispatch(setLoading({ doctor: [doctorId] }));
  };
};

export const setSelectedDoctorDispatch = (pageNo: number, doctorId: string) => {
  return async (dispach: AppDispatch) => {
    dispach(setSelectedDoctorFromList(pageNo, doctorId));
  };
};

export const setDoctorQueryDispatch = (query: doctorQuery) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setDoctorQuery(query));
  };
};

export const getDoctorSessionsDispatch = (
  doctorId: string,
  query: {
    pageSize: number;
    pageNumber: number;
  }
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ doctor: [doctorId] }));
    const response = await DoctorsApi.getSessions(doctorId, query);
    if (response.status != 200) {
      return;
    }
    dispatch(setDoctorSessions({ doctorId: doctorId, sessions: (response.data as any).sessions }));
    dispatch(setLoading({ doctor: [doctorId] }));
  };
};

export const getDoctorReviewsDispatch = (doctorId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ doctor: [doctorId] }));
    const response = await DoctorsApi.getDoctorReviews({ doctor: doctorId });

    if (response.status != 200) {
      return;
    }
    dispatch(setDoctorReviews(response.data));
    dispatch(setLoading({ doctor: [doctorId] }));
  };
};

export const setDoctorReviewDispatch = (review: ReviewResponse) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateDoctorReview(review));
  };
};
