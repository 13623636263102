import * as React from "react";

import Input from "../../components/input";
import Button from "../../components/button";
import Svg from "../../components/svg";

import CopyRight from "../../components/copyRight";

import LogoIcon from "../../assets/logo-2.svg";

import { Link } from "react-router-dom";
import dropdown from "../../components/dropdown";
import useRegister from "./useRegister";
import { clsx } from "../../utils/clsx";
import { useAppSelector } from "../../redux";

const Register: React.FC<any> = () => {
  const {
    translate,
    selectedLanguage,
    languagesData,
    form,
    onInputChange,
    submit,
    errors,
    countriesData
  } = useRegister();
  const countryRef = React.useRef<any>();
  const genderRef = React.useRef<any>();
  const loading = useAppSelector((state) => state.loading);
  return (
    <div className="register">
      <div className="register__logo">
        <img src={LogoIcon} alt="Clinio" />
      </div>
      <div className={clsx("register__form", loading.user ? "loading" : "")}>
        <div className="register__form__main-title">{translate("login__form__main_title")}</div>
        <div className="register__form__sub-title">{translate("login__form__sub_title")}</div>
        <form className="register__form__input-container">
          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="fullnameCurrentLang"
              autoComplete="name"
              onchange={onInputChange}
              adornment={
                <div
                  className="typo--m"
                  style={{
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    height: "2.4rem",
                    marginRight: "0.8rem",
                    marginLeft: "0.8rem"
                  }}
                >
                  {selectedLanguage?.name[selectedLanguage.language_code ?? "en"] ?? ""}
                </div>
              }
              placeholder={translate("register__form__name__placeholder")}
            />
            <div className="register__form__input-container__item__error">
              {errors.fullnameCurrentLang}
            </div>
          </div>
          <div className="register__form__input-container__item">
            <Input.InputNormal
              overrideStyles={{
                display: "flex",
                flexDirection: "row-reverse"

                // textAlign: `${form.lang ? "end" : "start"}` as any
              }}
              overrideInputStyles={{
                textAlign: `${form.lang?.r2l ? "right" : "left"}` as any
              }}
              name="fullnameOptionalLang"
              autoComplete="name"
              onchange={onInputChange}
              value={form.fullnameOptionalLang}
              adornment={
                <div style={{ display: "flex", alignItems: "center" }} className="typo--m">
                  <dropdown.DropdownLabels
                    data={languagesData
                      .filter((l) => selectedLanguage?._id != l._id)
                      .map((l) => ({
                        _id: l._id,
                        name: l.name[selectedLanguage?.language_code ?? "en"] ?? "en",
                        value: l
                      }))}
                    formName="lang"
                    onChange={onInputChange}
                    selectedItem={form.lang?._id}
                  />
                  <div
                    style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem", pointerEvents: "none" }}
                  >
                    {form.lang?.name[selectedLanguage?.language_code ?? "en"] ?? ""}
                  </div>
                </div>
              }
              // placeholder={translate("register__form__name__placeholder")}
            />
            <div className="register__form__input-container__item__error">
              {errors.fullnameOptionalLang}
            </div>
          </div>
          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="birthDate"
              autoComplete="bday"
              type="date"
              onchange={onInputChange}
              placeholder={translate("register__form__birth__placeholder")}
            />
            <div className="register__form__input-container__item__error">{errors.birthDate}</div>
          </div>
          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="name"
              onfocus={() => {
                genderRef.current.click();
              }}
              onblur={() => genderRef.current.click()}
              onchange={() => {
                // debugger;
                // (e.target?.parentElement as any).children[2].click();
              }}
              autoComplete="sex"
              value={form.gender?.name ?? ""}
              placeholder={translate("gender")}
              overrideInputStyles={{ cursor: "default" }}
              adornment={
                <dropdown.DropdownRadio
                  setRef={genderRef}
                  formName="gender"
                  onChange={onInputChange}
                  data={[
                    { name: translate("male"), _id: "male", value: "male" },
                    { name: translate("female"), _id: "female", value: "female" }
                  ]}
                  selectedItem={form.gender?._id ?? ""}
                />
              }
            />
            <div className="register__form__input-container__item__error">{errors.gender}</div>
          </div>

          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="name"
              autoComplete="country"
              onchange={() => {}}
              onfocus={() => {
                countryRef.current.click();
              }}
              onblur={() => countryRef.current.click()}
              value={form.nationailty?.value?.name[selectedLanguage?.language_code ?? "en"] ?? ""}
              placeholder={translate("register__form__nationality__placeholder")}
              adornment={
                <dropdown.DropdownRadio
                  formName="nationailty"
                  setRef={countryRef}
                  onChange={onInputChange}
                  data={countriesData}
                  selectedItem={form.nationailty?._id}
                />
              }
            />
          </div>
          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="phoneNumber"
              autoComplete="mobile"
              onchange={onInputChange}
              placeholder={translate("register__form__phone__placeholder")}
              pre={<div className="typo--m">+{form.nationailty?.value?.phone_code ?? "??"}</div>}
            />
            <div className="register__form__input-container__item__error">{errors.phoneNumber}</div>
          </div>
          <div
            className="register__form__input-container__item"
            style={{ marginLeft: "25%", marginRight: "25%" }}
          >
            <Input.InputNormal
              name="email"
              onchange={onInputChange}
              placeholder={translate("login__form__email__placeholder")}
            />
            <div className="register__form__input-container__item__error">{errors.email}</div>
          </div>
          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="password"
              type="password"
              autoComplete="new-paswords"
              onchange={onInputChange}
              placeholder={translate("login__form__password__placeholder")}
            />
            <div className="register__form__input-container__item__error">{errors.password}</div>
          </div>
          <div className="register__form__input-container__item">
            <Input.InputNormal
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              onchange={onInputChange}
              placeholder={translate("register__form__confirm_password__placeholder")}
            />
            <div className="register__form__input-container__item__error">
              {errors.confirmPassword}
            </div>
          </div>
        </form>
        <div className="register__form__actions">
          <div className="register__form__actions__button">
            <Button.ButtonWide action={submit} text={translate("register__form__create_new")} />
          </div>
        </div>
        <div className="register__form__login">
          <div className="register__form__login__text">
            {translate("register__form__has_already_account")}
          </div>
          <div className="register__form__login__link">
            <Link to="/login">{translate("navigation__login")}</Link>
          </div>
        </div>
      </div>
      <div className="register__copy-right">
        <CopyRight />
      </div>
    </div>
  );
};

export default Register;
