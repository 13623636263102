import { AxiosResponse } from "axios";
import { axios } from "./configuration";

export const pageLabeslApi = {
  getAll: async function (
    pageCode: string,
    lang: string
  ): Promise<AxiosResponse<{ [key: string]: string }>> {
    return axios.get(`/pagelabels/${pageCode}/${lang}`);
  }
};
