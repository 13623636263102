import * as React from "react";

export type SvgProps = {
  //in rem
  height: string;
  width: string;
  icon: string;
  customCss?: React.CSSProperties;
  // css variable & color
  color: string;
  reversable?: boolean;
};

const Svg: React.FC<SvgProps> = (props: SvgProps) => {
  if (!props.icon) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div className={`icon-container `}>
      <i
        className={`icon icon-img ${props.reversable ? "button--mirror" : ""}`}
        style={
          {
            ...props.customCss,
            "--width": `${props.width}`,
            "--height": `${props.height}`,
            "--background": `${props.color}`,
            "--maskImg": `url(${props.icon})`
          } as any
        }
      />
    </div>
  );
};

export default React.memo(Svg);
