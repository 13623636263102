import { AxiosResponse } from "axios";
import { InsuranceResponse } from "../interfaces";
import { axios } from "./configuration";

export const InsuranceApi = {
  getAll: async function (countryId: string): Promise<AxiosResponse<InsuranceResponse>> {
    return axios.get(`/insurance`, {
      params: {
        sys_country_code3: countryId
      }
    });
  }
};
