import React from "react";

import FilterIcon from "../../assets/filter";
// import Accordion from "../accordion/accordion";
import ArrowDown from "../../assets/arrow-down";

import { CheckBox } from "../checkbox";
import { clsx } from "../../utils/clsx";
import { useTranslation } from "../../hooks";

interface AccordionData {
  id: string; // identifer for label
  name: string; // the displayed title
  value: string | number; // the value should be returned
}

interface AccordionsData {
  title: string; // title of the big conatainer that holds checkboxes
  formName: string; // formname that should contain fallback name which contain all selected from checkbox ids
  data: AccordionData[];
  selectedData: (string | undefined)[];
  overrideItems?: (data: {
    accordionData: AccordionsData;
    accordionItem: AccordionData;
    accordionList: AccordionListProps;
    index: number;
  }) => JSX.Element;
}

interface AccordionListProps {
  data: AccordionsData[];
  loading?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccordionList: React.FC<AccordionListProps> = (props: AccordionListProps) => {
  const { translate } = useTranslation();
  const [open, setOpen] = React.useState<{
    [key: number]: boolean;
  }>({});

  const toggle = (idx: number) => {
    setOpen((_open) => ({ ..._open, [idx]: !_open[idx] }));
  };

  const _renderAccordion = React.useMemo(() => {
    const accordionItems = props.data.map((d, i) => {
      const checkBoxes = d.data.map((c, j) => (
        <li className="accordion__list__item" key={`${c.id}${j}`}>
          {d.overrideItems ? (
            <d.overrideItems accordionData={d} accordionItem={c} accordionList={props} index={j} />
          ) : (
            <CheckBox
              id={c.id}
              value={c.id}
              name={d.formName}
              title={c.name}
              onchange={props.onChange}
              checked={(d.selectedData as string[]).includes(c.value as string)}
            />
          )}
        </li>
      ));

      return (
        <React.Fragment key={i}>
          <div
            className={clsx("accordion__header", !open[i] && "open")}
            onClick={() => {
              toggle(i);
            }}
          >
            <div className="accordion__header__title">{d.title}</div>
            <div className={clsx("accordion__header__icon", !open[i] && "open")}>
              <ArrowDown />
            </div>
          </div>
          <ul className={clsx("accordion__list", !open[i] && "open")}>{checkBoxes}</ul>
        </React.Fragment>
      );
    });
    return <div className="accordion">{accordionItems}</div>;
  }, [open, props]);

  return (
    <div className={clsx("filters", props.loading ? "loading" : "")}>
      <div className="filters__header">
        <div className="filters__header__title">
          {translate("search__filter__header__main_title")}
        </div>
        <div className="filters__header__icon">
          <FilterIcon />
        </div>
      </div>
      <div className="filters__list">{_renderAccordion}</div>
    </div>
  );
};

export default AccordionList;
