import * as React from "react";
import StarIcon from "../../assets/star.svg";
import StarEmptyIcon from "../../assets/star-empty.svg";
import Svg from "../svg";
export type StarsProps = {
  noOfStars: number;
  noOfUsers?: number;
  selectAction?: (rating: number) => void;
  overrideContainerStyles?: React.CSSProperties;
};

const Stars: React.FC<StarsProps> = (props: StarsProps) => {
  const starClickHandle = React.useCallback(
    (rating: number) => {
      return () => {
        props.selectAction?.(rating);
      };
    },
    [props.selectAction]
  );

  const _renderStars = React.useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, i: number) => i)
      .map((v: number) => {
        if (v < props.noOfStars) {
          return (
            <div className="stars__icon" onClick={starClickHandle(v)} key={v}>
              <Svg
                color="var(--color-status-correct)"
                width="1.625rem"
                height="1.5rem"
                icon={StarIcon}
              />
            </div>
          );
        }

        return (
          <div className="stars__icon" onClick={starClickHandle(v)} key={v}>
            <Svg
              color="var(--color-status-correct)"
              width="1.525rem"
              height="1.4rem"
              icon={StarEmptyIcon}
            />
          </div>
        );
      });
  }, [starClickHandle, props.noOfStars]);

  const _renderUsers = () => {
    if (!props.noOfUsers) {
      return <React.Fragment></React.Fragment>;
    }
    return <div className=".typo--s">(تقييم {props.noOfUsers.toLocaleString("ar-eg")})</div>;
  };
  return (
    <div className="stars" style={props.overrideContainerStyles}>
      {_renderStars}
      {_renderUsers()}
    </div>
  );
};

export default Stars;
