import { AppDispatch } from "..";
import { BookingApi, BookingBody } from "../../api/bookingApi";
import { DoctorsState } from "../../interfaces";
import { setLoading } from "../actions/loadingActions";
import { setUserSessions } from "../actions/userActions";

export const bookDispatch = (
  bookingbody: BookingBody,
  doctorState: DoctorsState,
  token?: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ doctor: [bookingbody.doctor] }));
    const response = await BookingApi.book(bookingbody, token).catch((r) => r);
    dispatch(setLoading({ doctor: [bookingbody.doctor] }));
    if (response.status != 200) {
      return;
    }
    dispatch(setUserSessions(response.data.bookings));
  };
};
