import { RootPaginationAction, PaginationState } from "../../interfaces";
import { PaginationActionTypes } from "../../constants/actionTypes";

// reducer
const paginationState: PaginationState = {
  doctors: { count: 0, perPage: 10, currentPage: 1 },
  specialties: { count: 0, perPage: 10, currentPage: 1 }
};
const setCount = (state: PaginationState, count: number, keyName: keyof PaginationState) => ({
  ...state,
  doctors: { ...state[keyName], count }
});

const paginationReducer = (
  state: PaginationState = paginationState,
  action: RootPaginationAction
): PaginationState => {
  const { type, payload } = action;

  switch (type) {
    case PaginationActionTypes.SET_DOCTORS_COUNT:
      return setCount(state, payload as number, "doctors");
    case PaginationActionTypes.SET_SPECIALTIES_COUNT:
      return setCount(state, payload, "specialties");

    default:
      return state;
  }
};
export default paginationReducer;
