import { AxiosResponse } from "axios";
import { SubSpecialityResponse } from "../interfaces";
import { axios } from "./configuration";

export const subSpecialityApi = {
  getAll: async function (specialityIds: string[]): Promise<AxiosResponse<SubSpecialityResponse>> {
    return axios.get(`/subSpecialty`, {
      params: {
        specialty: specialityIds.join(",")
      }
    });
  }
};
