import { AppDispatch } from "..";
import { LanguagesApi } from "../../api/languageApi";
import { setLanguagesData, setSelectedLanguage } from "../actions/languageActions";
import { setLoading } from "../actions/loadingActions";

export const getLanguagesDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ labels: true }));
    const response = await LanguagesApi.getAll().catch((r) => r);

    dispatch(setLoading({ labels: false }));
    if (response.status != 200) {
    }
    dispatch(setLanguagesData(response.data.data ?? []));
  };
};

export const setLanguageDispatch = (languageId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedLanguage(languageId));
  };
};
