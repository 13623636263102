import { LoadingActionTypes } from "../../constants/actionTypes";
import { LoadingState } from "../../interfaces";

export const setLoading = (loading: LoadingState) => {
  return {
    type: LoadingActionTypes.SET_LOADING,
    payload: loading
  };
};

export const resetLoading = () => {
  return {
    type: LoadingActionTypes.RESET_LOADING,
    payload: undefined
  };
};
