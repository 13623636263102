import * as React from "react";

function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 9.897c0 .23-.08.458-.244.634l-3.763 4.031a1.34 1.34 0 01-1.981 0l-3.766-4.031a.94.94 0 010-1.268.796.796 0 011.183 0L12 13.094l3.572-3.827a.796.796 0 011.183 0 .91.91 0 01.244.63z"
        fill="#006FBB"
      />
    </svg>
  );
}

const MemoArrowDown = React.memo(ArrowDown);
export default MemoArrowDown;
