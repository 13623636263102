import React from "react";

const useErrors = <T>(initial?: T) => {
  const [errors, setErrors] = React.useState<T>(initial ?? ({} as T));
  const assignError = React.useCallback((field: keyof typeof errors, errMsg: string) => {
    setErrors((err) => {
      return { ...err, [field]: errMsg };
    });
  }, []);
  const clearError = React.useCallback((field: keyof typeof errors) => {
    setErrors((err) => {
      return { ...err, [field]: "" };
    });
  }, []);
  return {
    errors,
    assignError,
    clearError
  };
};

export default useErrors;
