import { Booking } from "../../api/bookingApi";
import { ReviewResponse } from "../../api/doctorsApi";
import { User } from "../../api/userApi";
import { UserActionTypes } from "../../constants/actionTypes";

export const setUserData = (user: User) => {
  return {
    type: UserActionTypes.SET_USER_DATA,
    payload: user
  };
};

export const setUserReviews = (reviews: ReviewResponse[]) => {
  return {
    type: UserActionTypes.SET_USER_REVIEWS,
    payload: reviews
  };
};

export const updateUserReview = (review: ReviewResponse) => {
  return {
    type: UserActionTypes.UPDATE_USER_REVIEW,
    payload: review
  };
};

export const setUserSessions = (bookings: Booking[]) => {
  return {
    type: UserActionTypes.SET_USER_SESSIONS,
    payload: bookings
  };
};
