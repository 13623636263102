import { AppDispatch } from "..";
import { ReviewBody, ReviewParams } from "../../api/doctorsApi";
import { UserApi, UserLoginBody, UserRegisterBody } from "../../api/userApi";

import { setLoading } from "../actions/loadingActions";

import { setUserData, setUserReviews, updateUserReview } from "../actions/userActions";
import { getDoctorByIdDispatch, getDoctorReviewsDispatch } from "./doctorsMiddlewares";

export const userLoginDispatch = (data: UserLoginBody) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ user: true }));
    const response = await UserApi.login(data).catch((r) => r);
    dispatch(setLoading({ user: false }));
    if (response.status != 200) {
      return;
    }
    dispatch(setUserData(response.data));
  };
};

export const userRegisterDispatch = (data: UserRegisterBody) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ user: true }));
    const response = await UserApi.register(data).catch((r) => r);
    dispatch(setLoading({ user: false }));
    if (response.status != 201) {
      return;
    }
    dispatch(setUserData(response.data.pat));
  };
};

export const userLogoutDispatch = (token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ user: true }));
    const response = await UserApi.logout(token).catch((r) => r);
    dispatch(setLoading({ user: false }));
    if (response.status != 200) {
      return;
    }
    dispatch(setUserData({ data: {} }));
  };
};

export const getUserReviewsDispatch = (params: ReviewParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ user: true }));
    const response = await UserApi.getReviews(params).catch((r) => r);

    dispatch(setLoading({ user: false }));
    if (response.status != 200) {
      return;
    }

    dispatch(setUserReviews(response.data));
  };
};

export const addUserReviewDispatch = (body: ReviewBody, token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ user: true }));
    const response = await UserApi.addUserReview(body, token).catch((r) => r);

    dispatch(setLoading({ user: false }));
    if (response.status != 201) {
      return;
    }
    dispatch(getDoctorReviewsDispatch(body.doctor));
    dispatch(getDoctorByIdDispatch(body.doctor));
    dispatch(updateUserReview(response.data));
  };
};

export const updateUserReviewDispatch = (reviewId: string, body: ReviewBody, token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ user: true }));
    const response = await UserApi.updateUserReview(reviewId, body, token).catch((r) => r);
    // debugger;
    dispatch(setLoading({ user: false }));
    if (response.status != 201) {
      return;
    }
    dispatch(getUserReviewsDispatch({ doctor: body.doctor, patient: body.patient }));
    dispatch(getDoctorReviewsDispatch(body.doctor));
  };
};
