import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks";
import useErrors from "../../hooks/useErrors";
import useForm from "../../hooks/useForm";

const useResetPassword = () => {
  const { translate } = useTranslation();
  const history = useHistory();
  const { form, onInputChange } = useForm<{ confirmPassword: string; password: string }>({
    confirmPassword: "",
    password: ""
  });
  const { errors, assignError, clearError } = useErrors<typeof form>({
    confirmPassword: "",
    password: ""
  });

  const validateOnPassword = React.useCallback(async () => {
    if (form.password.length < 6) {
      assignError("password", translate("login__form__password_error"));
      return false;
    }
    clearError("password");
    return true;
  }, [form.password]);

  const validateOnConfirmPassword = React.useCallback(() => {
    if (form.password != form.confirmPassword) {
      assignError("confirmPassword", translate("register__form__confirm_password_error"));
      return false;
    }
    clearError("confirmPassword");
    return true;
  }, [form.confirmPassword, form.password]);

  const submit = React.useCallback(() => {
    Promise.all([validateOnPassword(), validateOnConfirmPassword()]).then((res) => {
      if (res.every((v) => v == true)) {
        //submit
        history.push("/login");
      }
    });
  }, [validateOnPassword, validateOnConfirmPassword]);

  return {
    history,
    translate,
    form,
    onInputChange,
    errors,
    submit
  };
};

export default useResetPassword;
