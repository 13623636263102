import React, { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { Switch, Redirect, Route } from "react-router";
// import { createBrowserHistory } from "history";

import { routes } from "../routes";

import Navigation from "../layouts/navigation";
import SearchHeader from "../layouts/searchHeader";
import Breadcrumb from "../components/breadcrumb";

import Footer from "../layouts/footer";
import useApp from "./useApp";

// export const history = createBrowserHistory();

function App() {
  useApp();

  function renderRoute(r: typeof routes[number], i: number) {
    return (
      <Route key={i} exact path={r.route}>
        <div className="pageLayout">
          {r.searchHeader && (
            <Fragment>
              <SearchHeader />
              <Breadcrumb
                title={"Clinio"}
                locations={r.route
                  .split("/")
                  .filter(Boolean)
                  .map((_route) => {
                    return { href: r.route, title: _route };
                  })}
              />
            </Fragment>
          )}
          <r.component />
          {r.searchHeader && (
            <Fragment>
              <Footer />
            </Fragment>
          )}
        </div>
      </Route>
    );
  }

  function _renderNavigation() {
    return <Navigation />;
  }

  return (
    <BrowserRouter>
      {/* <Router history={history}> */}
      {_renderNavigation()}
      <Switch>
        <Redirect from="/home" to="/" />
        {routes.map(renderRoute)}
        <Route
          render={() => {
            return (
              <h1 className="text-center mt-5">
                Page you request is
                <br /> Not Found
                <i className="fa fa-warning" />
              </h1>
            );
          }}
        />
      </Switch>
      {/* </Router> */}
    </BrowserRouter>
  );
}

export default App;
