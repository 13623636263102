import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReviewResponse } from "../../api/doctorsApi";
import { useTranslation } from "../../hooks";
import useErrors from "../../hooks/useErrors";
import useForm from "../../hooks/useForm";
import { useAppDispatch, useAppSelector } from "../../redux";

import {
  getDoctorByIdDispatch,
  getDoctorReviewsDispatch,
  getDoctorsDispatch
} from "../../redux/middlewares/doctorsMiddlewares";
import {
  getInsurancesDispatch,
  setSelectedInsurancesDispatch
} from "../../redux/middlewares/insuranceMiddlewares";
import {
  addUserReviewDispatch,
  getUserReviewsDispatch,
  updateUserReviewDispatch
} from "../../redux/middlewares/userMiddlewares";

const useDoctorDetails = () => {
  const dispatch = useAppDispatch();
  const { language, translate } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const selectedDoctor: any = useAppSelector((state) => {
    const v: any = state.doctors.selectedDoctor;
    if (!v) {
      return;
    }
    return {
      ...v,
      name: v.name?.[language ?? "en"] ?? v.name?.["en"],
      address: v.address?.[language ?? "en"] ?? v.address?.["en"],
      workingHours: v.workingHours?.[language ?? "en"] ?? v.workingHours?.["en"],
      description: v.description?.[language ?? "en"] ?? v.description?.["en"]
    };
  });
  const doctorReviews = useAppSelector((state) => state.doctors.doctorReviews);
  const countryId = useAppSelector((state) => state.countries.selectedCountry?._id);
  const query = useAppSelector((state) => state.doctors.query);

  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { form: DoctorListForm, onInputChange } = useForm();
  const { form: reviewForm, setForm: setReviewForm, onInputChange: onInputReviewChange } = useForm<{
    reviewId: string;
    comment: string;
    rate: number;
    type: "add" | "edit" | "delete" | "none";
  }>({
    reviewId: "",
    comment: "",
    rate: 0,
    type: "none"
  });

  const { errors, assignError, clearError } = useErrors<typeof reviewForm>({
    comment: "",
    rate: 0,
    type: "none",
    reviewId: ""
  });
  const validateOnComment = React.useCallback(() => {
    if (reviewForm.comment.length == 0 || reviewForm.comment.length > 400) {
      assignError("comment", "Comments should be less than 400 characters");
      return false;
    }
    clearError("comment");
    return true;
  }, [reviewForm.comment]);

  const { insuranceCompanies } = useAppSelector((state) => {
    return {
      insuranceCompanies: state.insurance.data
        .filter((d) =>
          selectedDoctor?.insurance_id?.map((v: any) => v.insurance as string).includes(d._id)
        )
        .map((v) => {
          return { ...v, name: v.name?.[language ?? "en"] ?? v.name?.["en"] };
        })
    };
  });

  React.useEffect(() => {
    if (!countryId) {
      return;
    }
    dispatch(getInsurancesDispatch(countryId));
  }, [countryId]);

  React.useEffect(() => {
    //   if no selected doctor or the selected doctor is not same as the url params then  go feach

    if (selectedDoctor?._id != params.id) {
      dispatch(getDoctorByIdDispatch(params.id));
    }
  }, [selectedDoctor?._id]);

  const onInsuranceTagPress = React.useCallback(
    (insuranceId) => () => {
      if (!insuranceId) {
        return;
      }

      dispatch(setSelectedInsurancesDispatch([insuranceId]));
      dispatch(getDoctorsDispatch(Object.assign(query, { insurance: [insuranceId] })));
      history.push("/search");
    },
    [query]
  );
  React.useEffect(() => {
    if (!selectedDoctor?._id || !user?.data?._id) {
      return;
    }
    dispatch(getDoctorReviewsDispatch(selectedDoctor?._id));
    dispatch(
      getUserReviewsDispatch({
        doctor: selectedDoctor?._id,
        patient: user?.data?._id
      })
    );
  }, [user?.data?._id, selectedDoctor?._id]);

  const resetReviewForm = React.useCallback(() => {
    setReviewForm({
      reviewId: "",
      comment: "",
      rate: 0,
      type: "none"
    });
  }, []);
  const onReviewUpdateSubmit = React.useCallback(() => {
    // dispatch
    if (!user?.token || !validateOnComment()) {
      return;
    }
    dispatch(
      updateUserReviewDispatch(
        reviewForm.reviewId,
        {
          comment: reviewForm.comment,
          doctor: selectedDoctor?._id,
          patient: user?.data?._id,
          rate_stars: reviewForm.rate
        },
        user?.token
      )
    );
    resetReviewForm();
    console.log("updated comment");
  }, [
    user?.token,
    user?.data?._id,
    selectedDoctor?._id,
    validateOnComment,
    reviewForm.comment,
    reviewForm.rate,
    resetReviewForm
  ]);
  const onReviewAddSubmit = React.useCallback(() => {
    // edit review
    if (!validateOnComment()) {
      return;
    }
    dispatch(
      addUserReviewDispatch(
        {
          comment: reviewForm.comment,
          doctor: selectedDoctor?._id ?? "",
          patient: user?.data?._id ?? "",
          rate_stars: reviewForm.rate
        },
        user.token ?? ""
      )
    );
    resetReviewForm();
  }, [
    user?.token,
    user?.data?._id,
    selectedDoctor?._id,
    reviewForm.comment,
    reviewForm.rate,
    validateOnComment,
    resetReviewForm
  ]);
  const onReviewCancel = React.useCallback(() => {
    // cancel review
    setReviewForm({ comment: "", reviewId: "", rate: 0, type: "none" });
    clearError("comment");
  }, []);
  const onReviewDelete = React.useCallback(
    (reviewId: string) => {
      setReviewForm((old) => {
        return { ...old, reviewId: reviewId, type: "delete" };
      });
    },
    [reviewForm.reviewId]
  );
  const onReviewEdit = React.useCallback((r: ReviewResponse) => {
    setReviewForm({
      reviewId: r._id,
      comment: r.comment,
      rate: r.rate_stars,
      type: "edit"
    });
  }, []);
  const onReviewDeleteSubmit = React.useCallback(() => {
    // dispatch del
    resetReviewForm();
    console.log("delete comment");
  }, [resetReviewForm]);
  return {
    selectedDoctor,
    language,
    translate,
    DoctorListForm,
    onInputChange,
    insuranceCompanies,
    onInsuranceTagPress,
    reviewForm,
    setReviewForm,
    onInputReviewChange,
    onReviewAddSubmit,
    onReviewCancel,
    onReviewDelete,
    onReviewEdit,

    errors,
    onReviewUpdateSubmit,
    onReviewDeleteSubmit,
    user,
    doctorReviews
  };
};
export default useDoctorDetails;
