import * as React from "react";
import Button from "../../components/button";
import DownIcon from "../../assets/down.svg";
import TwitterIcon from "../../assets/twitter.svg";
import LinkedInIcon from "../../assets/linkedin.svg";
import CallIcon from "../../assets/navigation-call.svg";
import FacebookIcon from "../../assets/facebook.svg";
import WhatsappIcon from "../../assets/whatsapp.svg";
import InstagramIcon from "../../assets/instagram.svg";

import useNavigation from "./useNavigation";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { clsx } from "../../utils/clsx";

const Navigation: React.FC = () => {
  const {
    countriesData,
    selectedCountry,
    onCountrySelect,
    onLanguageSelect,
    language,
    languagesData,
    selectedLanguage,
    translate,
    onLogout,
    user
  } = useNavigation();
  const loading = useAppSelector((state) => state.loading);
  const [isCountryVisible, setIsCountryVisible] = React.useState(false);
  const [isLanguageVisible, setIsLanguageVisible] = React.useState(false);
  const onClickCountryDropDown = () => {
    setIsLanguageVisible(false);
    setIsCountryVisible((_isVisible) => !_isVisible);
  };

  const _renderLanguagesDropdown = React.useMemo(() => {
    return languagesData.map((l, i) => {
      return (
        <React.Fragment key={l._id ?? i}>
          <div
            className="drpdwn__content__item"
            onClick={() => {
              l._id && onLanguageSelect(l._id);
              setIsLanguageVisible(!isLanguageVisible);
            }}
          >
            <span className="drpdwn__content__item__label">
              {l.name[l.language_code ?? "en"] ?? l.name["en"]}
            </span>
          </div>
          {i < languagesData.length - 1 && (
            <div className="divider" style={{ width: "100%" }}></div>
          )}
        </React.Fragment>
      );
    });
  }, [languagesData, isLanguageVisible, onLanguageSelect]);

  const _renderCountriesDropdown = () => {
    return countriesData.map((c, i) => {
      return (
        <React.Fragment key={c._id ?? i}>
          <div
            className="drpdwn__content__item"
            onClick={() => {
              c._id && onCountrySelect(c._id);
              setIsCountryVisible(!isCountryVisible);
            }}
          >
            <div className="drpdwn__content__item__icon">
              <img
                src="https://flagcdn.com/eg.svg"
                className="drpdwn__button__flag"
                alt="Egypt"
                // width="24"
                // height="17"
              />
            </div>
            <span className="drpdwn__content__item__label">{c.name}</span>
          </div>
          {i < countriesData.length - 1 && (
            <div className="divider" style={{ width: "100%" }}></div>
          )}
        </React.Fragment>
      );
    });
  };
  return (
    <div className="grid grid-columns-12">
      <div
        dir="ltr"
        className={clsx(
          "navigation navigation--primary grid-labtop-span-12 grid-iPad-span-12 grid-gutters",
          loading.user || loading.country || loading.labels ? "loading" : ""
        )}
      >
        <div className="navigation-side">
          <div className="navigation__social">
            <Button.ButtonRounded
              svg={{
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-gray-8)",
                reversable: false,
                icon: TwitterIcon
              }}
            />
            <Button.ButtonRounded
              svg={{
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-gray-8)",
                reversable: false,
                icon: LinkedInIcon
              }}
            />
            <Button.ButtonRounded
              svg={{
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-gray-8)",
                reversable: false,
                icon: FacebookIcon
              }}
            />
            <Button.ButtonRounded
              svg={{
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-gray-8)",
                reversable: false,
                icon: WhatsappIcon
              }}
            />
            <Button.ButtonRounded
              svg={{
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-gray-8)",
                reversable: false,
                icon: InstagramIcon
              }}
            />
          </div>
          <ul className="navigation__links">
            <li className="navigation__links__item">
              <a href="#" className="navigation__links__item__link">
                {translate("navigation__terms")}
              </a>
            </li>
            <li className="navigation__links__item">
              <a href="#" className="navigation__links__item__link">
                {translate("navigation__FAQ")}
              </a>
            </li>
            <li className="navigation__links__item">
              <a href="#" className="navigation__links__item__link">
                {translate("navigation__privacy")}
              </a>
            </li>
          </ul>
        </div>
        <div dir="ltr" className="navigation-side">
          <a href="#" className="navigation__contact">
            <b className="navigation__contact__number">{translate("clinio__contact_number")}</b>
            <img className="navigation__contact__icon" src={CallIcon} alt="call hotline" />
            <span className="navigation__contact__label">
              {translate("navigation__contact_us")}
            </span>
          </a>
          {user.token ? (
            <div className="navigation__register">
              {user?.data?.name?.first[selectedLanguage?.language_code ?? "en"] +
                " " +
                user?.data?.name?.father[selectedLanguage?.language_code ?? "en"]}
            </div>
          ) : (
            <Link to="/register" className="navigation__register">
              {translate("navigation__register")}
            </Link>
          )}
          {!user.token ? (
            <Link to="/login" className="navigation__login">
              {translate("navigation__login")}
            </Link>
          ) : (
            <div onClick={onLogout} className="navigation__login">
              {translate("logout")}
            </div>
          )}
          <div dir="ltr" className="drpdwn">
            <button dir="ltr" onClick={onClickCountryDropDown} className="drpdwn__button">
              <img
                src="https://flagcdn.com/eg.svg"
                className="drpdwn__button__flag"
                alt="Egypt"
                width="24"
                height="17"
              />
              <span className="drpdwn__button__label">{selectedCountry?.name}</span>
              <img src={DownIcon} alt="down icon" />
            </button>
            <div
              className={`drpdwn__content${isCountryVisible ? " drpdwn__content--visible" : ""}`}
            >
              {_renderCountriesDropdown()}
            </div>
          </div>

          <div dir="ltr" className="drpdwn" style={{ padding: "1.6rem" }}>
            <button
              dir="ltr"
              onClick={() => {
                setIsCountryVisible(false);
                setIsLanguageVisible((_isVisible) => !_isVisible);
              }}
              className="drpdwn__button"
            >
              <span className="drpdwn__button__label">
                {selectedLanguage?.name[language ?? "en"] ?? selectedLanguage?.name["en"]}
              </span>
              <img src={DownIcon} alt="down icon" />
            </button>
            <div
              className={`drpdwn__content${isLanguageVisible ? " drpdwn__content--visible" : ""}`}
            >
              {_renderLanguagesDropdown}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
