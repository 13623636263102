import { AppDispatch } from "..";
import { InsuranceApi } from "../../api/insuranceApi";

import { setDoctorQuery } from "../actions/doctorActions";
import {
  resetSelectedInsurances,
  setInsuranceData,
  setSelectedInsurances
} from "../actions/insuranceActions";
import { setLoading } from "../actions/loadingActions";

export const getInsurancesDispatch = (countryId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ filter: true }));
    const response = await InsuranceApi.getAll(countryId).catch((r) => r);
    dispatch(setLoading({ filter: false }));
    if (response.status != 200) {
      return;
    }
    dispatch(setInsuranceData(response.data));
  };
};
export const setSelectedInsurancesDispatch = (insuranceIds: string[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedInsurances(insuranceIds));
    dispatch(setDoctorQuery({ insurance: insuranceIds }));
  };
};

export const resetSelectedInsurancesDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedInsurances());
  };
};
