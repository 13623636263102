import { LoadingState, RootLoadingAction } from "../../interfaces";
import { LoadingActionTypes } from "../../constants/actionTypes";

// reducer
const loadingState: LoadingState = {
  area: false,
  country: false,
  doctor: [],
  doctors: false,
  labels: false,
  region: false,
  reviews: false,
  specialities: false
};

const setLoading = (state: LoadingState, payload: LoadingState): LoadingState => {
  return {
    ...state,
    ...payload,
    doctor: [
      ...(state.doctor?.filter((d) => !payload.doctor?.includes(d)) ?? []),
      ...(payload.doctor?.filter((d) => !state.doctor?.includes(d)) ?? [])
    ]
  };
};

const LoadingReducer = (
  state: LoadingState = loadingState,
  { type, payload }: RootLoadingAction
): LoadingState => {
  switch (type) {
    case LoadingActionTypes.SET_LOADING: {
      return setLoading(state, payload as LoadingState);
    }
    case LoadingActionTypes.RESET_LOADING: {
      return setLoading(state, loadingState);
    }
    default:
      return state;
  }
};
export default LoadingReducer;
