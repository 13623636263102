import * as React from "react";

export type ListProps = {
  name: string;
  data: { _id?: string; name?: string }[];
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedItemId: string;
};

const List: React.FC<ListProps> = (props: ListProps) => {
  function _renderDynamicSVG(selected: boolean = false) {
    return (
      <svg
        width="5"
        height="8"
        viewBox="0 0 5 8"
        fill={selected ? `var(--color-blue-1,#ff0000)` : `var(--color-gray-4,#ff0000)`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.25257 8C4.06161 8 3.87065 7.93584 3.72445 7.80484L0.364762 4.79465C-0.121587 4.3589 -0.121587 3.64511 0.364762 3.20936L3.72445 0.196491C4.01686 -0.0654971 4.48829 -0.0654971 4.7807 0.196491C5.0731 0.45848 5.0731 0.880869 4.7807 1.14286L1.58809 4.00067L4.77771 6.85848C5.07012 7.12047 5.07012 7.54286 4.77771 7.80484C4.63449 7.93584 4.44353 8 4.25257 8Z" />
      </svg>
    );
  }

  const _renderItems = React.useMemo(() => {
    return props.data.map((d, i) => {
      return (
        <React.Fragment key={d?._id || i}>
          <li value={d?._id}>
            <input
              type="radio"
              name={props.name}
              value={d?._id}
              id={d?._id}
              hidden
              onChange={props.onSelect}
            />
            <label
              htmlFor={d?._id}
              className={`list__item${props.selectedItemId === d?._id ? " selected" : ""}`}
            >
              <div className="list__item__text"> {d?.name}</div>
              <div className="list__item__icon mirror">
                {_renderDynamicSVG(props.selectedItemId === d?._id)}
              </div>
            </label>
            {i < props.data.length - 1 && <div className="divider"></div>}
          </li>
        </React.Fragment>
      );
    });
  }, [props.data, props.selectedItemId, props.onSelect, props.name]);

  return <ul className="list">{_renderItems}</ul>;
};

export default List;
