import * as React from "react";
import { clsx } from "../../utils/clsx";
import Svg from "../svg";
import { SvgProps } from "../svg/svg";

export type ButtonProps = {
  //
  classes: string;
  svg?: SvgProps;
  text: string;
  action: any; //function
  overrideStyles?: React.CSSProperties;
  textClasses?: string;
};

const ButtonNormal: React.FC<ButtonProps> = (props: ButtonProps) => {
  const _renderButtonIcon = React.useMemo(() => {
    if (!props.svg) {
      return <React.Fragment></React.Fragment>;
    }

    return (
      <Svg
        color={props.svg.color}
        height={props.svg.height}
        width={props.svg.width}
        icon={props.svg.icon}
        customCss={props.svg.customCss}
        reversable={props.svg.reversable}
      />
    );
  }, [props.svg]);

  const _renderText = React.useMemo(() => {
    if (!props.text) {
      return <React.Fragment></React.Fragment>;
    }
    return <div className={`button__text ${props.textClasses}`}>{props.text}</div>;
  }, [props.text]);

  return (
    <div
      style={props.overrideStyles ?? {}}
      className={`button ${props.classes ?? ""}`}
      onClick={props.action}
    >
      {_renderButtonIcon}
      {_renderText}
    </div>
  );
};

export type ButtonWideProps = {
  text: string;
  action: any;
  overrideStyles?: React.CSSProperties;
};

const ButtonWide: React.FC<ButtonWideProps> = (props: ButtonWideProps) => {
  return (
    <ButtonNormal
      action={props.action}
      text={props.text}
      classes={`button--wide button--primary`}
      overrideStyles={props.overrideStyles ?? {}}
    />
  );
};

export type ButtonRoundedProps = {
  action?: any;
  svg: SvgProps;
  nonreversable?: boolean;
  disabled?: boolean;
  classes?: string;
  overrideStyles?: React.CSSProperties;
};

const ButtonRounded: React.FC<ButtonRoundedProps> = (props: ButtonRoundedProps) => {
  return (
    <ButtonNormal
      action={props.action}
      text={""}
      classes={`button--rounded button--primary ${props.classes} ${
        props.disabled ? "disabled" : ""
      }`}
      svg={props.svg}
      overrideStyles={props.overrideStyles ?? {}}
    />
  );
};

export type ButtonMediumProps = {
  action: any;
  text: string;
  classes?: string;
  overrideStyles?: React.CSSProperties;
};

const ButtonMeduim: React.FC<ButtonMediumProps> = (props: ButtonMediumProps) => {
  return (
    <ButtonNormal
      action={props.action}
      text={props.text}
      classes={props.classes ?? `button--medium button--primary`}
      overrideStyles={props.overrideStyles ?? {}}
    />
  );
};

export type ButtonSmallProps = {
  action: any;
  text: string;
  classes?: string;
  overrideStyles?: React.CSSProperties;
  textClasses?: string;
};

const ButtonSmall: React.FC<ButtonSmallProps> = (props: ButtonSmallProps) => {
  return (
    <ButtonNormal
      action={props.action}
      text={props.text}
      classes={`button--small button--primary ${props.classes ?? ""}`}
      overrideStyles={props.overrideStyles ?? {}}
      textClasses={props.textClasses}
    />
  );
};
export type ButtonTagProps = {
  action: any;
  text: string;
  classes?: string;
  overrideStyles?: React.CSSProperties;
};

const ButtonTag: React.FC<ButtonTagProps> = (props: ButtonTagProps) => {
  return (
    <ButtonNormal
      action={props.action}
      text={props.text}
      classes={props.classes ?? `button--tag`}
      overrideStyles={props.overrideStyles ?? {}}
    />
  );
};

export type ButtonSelectProps = {
  action: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  classes?: string;
  selected?: boolean;
  overrideStyles?: React.CSSProperties;
  overrideSelectClass?: string;
  name: string;
  value: string;
};

const ButtonSelect: React.FC<ButtonSelectProps> = (props: ButtonSelectProps) => {
  return (
    <label htmlFor={props.value}>
      <div
        style={props.overrideStyles}
        className={clsx(
          "button",
          "button--selectable",
          "button--selectable__text",
          props.selected ? "selected" : ""
        )}
      >
        {props.text}
      </div>
      <input
        hidden
        id={props.value}
        value={props.value}
        type="radio"
        name={props.name}
        onClick={props.action as any}
      />
    </label>
  );
};

export default {
  ButtonNormal,
  ButtonWide,
  ButtonRounded,
  ButtonSelect,
  ButtonMeduim,
  ButtonSmall,
  ButtonTag
};
