import * as React from "react";
import Stars from "../stars";
import { StarsProps } from "../stars/stars";
import Svg from "../svg";

import CheckIcon from "../../assets/check.svg";
import PenIcon from "../../assets/pen.svg";
import DeleteIcon from "../../assets/delete.svg";
import QouteIcon from "../../assets/quote.svg";
import TrashIcon from "../../assets/trash.svg";
import Button from "../../components/button";
export type ReviewProps = {
  rating: StarsProps;
  comment: string;
  name: string;
  date: Date;
  overrideContainerStyles?: React.CSSProperties;
  language?: string;
  review?: {
    action?: "add" | "edit" | "delete" | "none";
    show: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onCancel: () => void;
    onUpdate: () => void;
    onDelete: () => void;
    shouldUpdate?: boolean;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    name: string;
    value: string;
  };
};

const Review: React.FC<ReviewProps> = (props: ReviewProps) => {
  return (
    <div className="review" style={props.overrideContainerStyles}>
      <div className="review__stars">
        <Stars noOfStars={props.rating.noOfStars} selectAction={props.rating.selectAction} />
        {props.review?.shouldUpdate ? (
          <div style={{ display: "flex" }}>
            <Button.ButtonRounded
              svg={{
                width: "1.4rem",
                height: "1.5rem",
                color: "var(--color-gray-8)",
                icon: PenIcon
              }}
              overrideStyles={{ background: "var(--color-status-correct)", margin: "0.4rem" }}
              action={props.review.onEditClick}
            />
            <Button.ButtonRounded
              svg={{
                width: "1.6rem",
                height: "1.4rem",
                color: "var(--color-gray-8)",
                icon: TrashIcon
              }}
              overrideStyles={{ background: "var(--color-status-delete)", margin: "0.4rem" }}
              action={props.review.onDeleteClick}
            />
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
      <div className="review__comment-container">
        <div className="review__comment__quote-left">
          <Svg
            color="var(--color-blue-1)"
            height="1.2rem"
            width="1.2rem"
            icon={QouteIcon}
            reversable
          />
        </div>
        {props.review?.show ? (
          <React.Fragment>
            <textarea
              // type="text"
              disabled={props.review.action == "delete"}
              name={props.review?.name}
              defaultValue={
                props.review.action == "delete"
                  ? "Are you sure you want to delete this comment"
                  : props.review?.value
              }
              // value={props.addReview?.value}

              className="typo--m"
              style={{
                width: "100%",
                border: "0px",
                height: "3.4rem",
                borderRadius: "1rem",
                padding: "0.4rem 2rem 0.4rem 2rem"
              }}
              onChange={props.review?.onChange as any}
            />

            <div className="review__comment__quote-right">
              <Svg
                color="var(--color-blue-1)"
                height="1.2rem"
                width="1.2rem"
                css={{ transform: "180deg" }}
                icon={QouteIcon}
                reversable
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button.ButtonRounded
                svg={{
                  width: "1.4rem",
                  height: "1.4rem",
                  color: "var(--color-gray-8)",
                  icon: CheckIcon
                }}
                overrideStyles={{ background: "var(--color-status-correct)", margin: "0.4rem" }}
                action={() => {
                  switch (props.review?.action) {
                    case "add":
                      {
                        props.review.onSubmit();
                      }
                      return;
                    case "edit":
                      {
                        props.review.onUpdate();
                      }
                      return;
                    case "delete":
                      {
                        props.review.onDelete();
                      }
                      return;
                    default:
                      return;
                  }
                }}
              />
              <Button.ButtonRounded
                svg={{
                  width: "2.4rem",
                  height: "2.4rem",
                  color: "var(--color-status-delete)",
                  reversable: true,
                  icon: DeleteIcon
                }}
                overrideStyles={{ background: "var(--color-gray-8)", margin: "0.4rem" }}
                action={props.review?.onCancel}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="review__comment">{props.comment}</div>
        )}
        {props.review?.show ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className="review__comment__quote-right">
            <Svg
              color="var(--color-blue-1)"
              height="1.2rem"
              width="1.2rem"
              css={{ transform: "180deg" }}
              icon={QouteIcon}
              reversable
            />
          </div>
        )}
      </div>
      <div className="review__divider"></div>
      <div className="review__details">
        {props.name} -
        {props.date.toLocaleTimeString(props.language == "ar" ? "ar-eg" : "en-uk", {
          weekday: "long",
          day: "numeric",
          year: "numeric",
          month: "long",
          hour12: true
        })}
      </div>
    </div>
  );
};

export default Review;
