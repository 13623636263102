import { AppDispatch } from "..";
import { subSpecialityApi } from "../../api/subSpecialityApi";

import { setDoctorQuery } from "../actions/doctorActions";
import { setLoading } from "../actions/loadingActions";
import { setSelectedSubSpecialities, setSubSpecialityData } from "../actions/subSpecialityActions";

export const getSubSpecialitiesDispatch = (specialityIds?: string[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ filter: true }));
    const response = await subSpecialityApi.getAll(specialityIds ?? []).catch((r) => r);
    dispatch(setLoading({ filter: false }));
    if (response.status != 200) {
      return;
    }
    dispatch(setSubSpecialityData(response.data.data));
  };
};

export const setSelectedSubSpecialitiesDispatch = (subSpecialityIds?: string[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedSubSpecialities(subSpecialityIds ?? []));
    dispatch(setDoctorQuery({ subSpecialties: subSpecialityIds ?? [] }));
  };
};
