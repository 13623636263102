import * as React from "react";

import Input from "../../components/input";
import Button from "../../components/button";
import Svg from "../../components/svg";

import LogoIcon from "../../assets/logo-2.svg";
import CallIcon from "../../assets/call.svg";

import CopyRight from "../../components/copyRight";
import { Link } from "react-router-dom";
import { useLogin } from "./useLogin";
import { useAppSelector } from "../../redux";
import { clsx } from "../../utils/clsx";

const Login: React.FC<any> = () => {
  const { onInputChange, translate, history, errors, submit } = useLogin();
  const loading = useAppSelector((state) => state.loading);
  // <dropdown.DropdownRadio
  //   data={[
  //     { label: "msale", value: "male" },
  //     { label: "femasle", value: "female" }
  //   ]}
  //   selectedItem={form.gender}
  //   formName="gender"
  //   onChange={onInputChange}
  // />
  return (
    <div className="login">
      <div className="login__logo">
        <img src={LogoIcon} alt="Clinio" />
      </div>
      <div className={clsx("login__form", loading.user ? "loading" : "")}>
        <div className="login__form__main-title">{translate("login__form__main_title")}</div>
        <div className="login__form__sub-title">{translate("login__form__sub_title")}</div>
        <div className="login__form__input-container">
          <div className="login__form__input-container__item">
            <Input.InputNormal
              name="email"
              autoComplete="email"
              onchange={(event) => {
                onInputChange(event);
              }}
              placeholder={translate("login__form__email__placeholder")}
              adornment={<div> </div>}
            />
          </div>
          <div className="login__form__input-container__item-error">{errors.email}</div>
          <div className="login__form__input-container__item">
            <Input.InputNormal
              name="password"
              type="password"
              autoComplete="password"
              onchange={onInputChange}
              placeholder={translate("login__form__password__placeholder")}
            />
          </div>
          <div className="login__form__input-container__item-error">{errors.password}</div>
        </div>
        <div className="login__form__forget-pass">
          <Link to="/forgetpassword"> {translate("login__form__forget_pass")}</Link>
        </div>
        <div className="login__form__actions">
          <div className="login__form__actions__button">
            <Button.ButtonWide action={submit} text={translate("navigation__login")} />
          </div>
          <div className="login__form__actions__button">
            <Button.ButtonWide
              action={() => {
                history.push("/register");
              }}
              overrideStyles={{
                backgroundColor: "var(--color-gray-8)",
                color: "var(--color-blue-1)",
                border: "1px solid var(--color-blue-1)"
              }}
              text={translate("login__form__register")}
            />
          </div>
        </div>
        <div className="login__form__main-catch-phrase">
          {translate("home__search__main_catch_phrase")}
        </div>
        <div className="login__form__sub-catch-phrase">
          {translate("home__search__sub_catch_phrase")}
        </div>
        <div className="login__form__contact">
          {translate("clinio__contact_number")}
          <div className="login__form__contact__icon">
            <Svg width="2rem" height="2rem" color="var(--color-gray-3)" icon={CallIcon} />
          </div>
        </div>
      </div>
      <div className="login__copy-right">
        <CopyRight />
      </div>
    </div>
  );
};

export default Login;
