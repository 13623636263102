import * as React from "react";
import { useTranslation } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../redux";
import { bookDispatch } from "../../redux/middlewares/bookingMiddlewares";

import { clsx } from "../../utils/clsx";
import Button from "../button";

export type CalendarProps = {
  name: string;
  doctorId: string;
  patientId: string;
  header?: string;
  allowBooking: boolean;
  alreadyBooked?: boolean;
  bbtFlag: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedDate?: string; // ISO DATE
  data: any[]; // backend keeps changing this if you want to see types check  https://app.swaggerhub.com/apis/mayadaGhanem/clinioDir/1.0.0#/default/post_booking
};

const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { translate } = useTranslation();
  const { selectedLanguage } = useAppSelector((state) => state.language);
  const { selectedCountry } = useAppSelector((state) => state.countries);

  const dispatch = useAppDispatch();
  const doctorState = useAppSelector((state) => state.doctors);
  const userToken = useAppSelector((state) => state.user.token);
  const submit = React.useCallback(() => {
    if (!props.bbtFlag) {
      const seq: any = { ...props.data[0] };
      const avilabledSessions = (seq.periods as any[]).filter((v) => props.selectedDate == v.from);

      if (!avilabledSessions.length) {
        return;
      }
      const session = avilabledSessions[0];
      dispatch(
        bookDispatch(
          {
            BBTflag: props.bbtFlag,
            bookingsetup: seq.bookingsetup,
            doctor: props.doctorId,
            patient: props.patientId,
            session: {
              session_no: session.session.session_no,
              from: seq.date
            },
            patients: session.patients,
            time_end: session.time_end
          } as any,
          doctorState,
          userToken
        )
      );
      return;
    }
    const selected = props.data.filter((d) => d.date == props.selectedDate);
    if (!selected.length && props.bbtFlag) {
      return;
    }
    let booking = { ...selected[0] };
    dispatch(
      bookDispatch(
        {
          doctor: props.doctorId,
          patient: props.patientId,
          patients: booking.patients,
          BBTflag: props.bbtFlag,
          bookingsetup: booking.bookingsetup,
          session: { ...booking.session, from: booking.date },
          slot_time: booking.slot_time,
          time_end: booking.time_end
        },
        doctorState,
        userToken
      )
    );
  }, [props.data, props.selectedDate, doctorState, userToken]);

  const _renderItems = React.useMemo(() => {
    // doctor doesnt allow to show his bookings

    if (props.allowBooking == false) {
      return <React.Fragment></React.Fragment>;
    }
    // doctor has booking by reservation priority
    if (props.bbtFlag == false) {
      return props.data.map((v, i) => {
        return (
          <div key={i}>
            {v.periods?.map((p: any, j: number) => (
              <label
                style={{ marginTop: "2rem" }}
                className={clsx(
                  "calendar__body__item calendar__body__item",
                  props.selectedDate == p.from && "selected"
                )}
                key={`${i}${j}`}
                htmlFor={p.from}
              >
                <input
                  id={p.from}
                  type="checkbox"
                  name={props.name}
                  value={new Date(p.from).toISOString()}
                  onChange={props.onChange}
                  hidden
                />
                <div style={{ marginTop: "" }}>
                  {translate("from")}:
                  {new Date(p.from).toLocaleTimeString(
                    `${selectedLanguage?.language_code ?? "en"}-uk`,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true
                    }
                  )}
                </div>
                <div>
                  {translate("to")}:
                  {new Date(p.to).toLocaleTimeString(
                    `${selectedLanguage?.language_code ?? "en"}-uk`,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true
                    }
                  )}
                </div>
              </label>
            ))}
          </div>
        );
      });
    }
    // doctor has time table
    return props.data.map((v, i) => {
      return (
        <label
          htmlFor={v.date}
          className={clsx("calendar__body__item", props.selectedDate == v.date && "selected")}
          key={i}
        >
          {/* based on country date locate code */}
          {new Date(v.date).toLocaleTimeString(`${selectedLanguage?.r2l ? "ar-eg" : "en-uk"}`, {
            hour: "2-digit",
            minute: "2-digit",
            // dayPeriod: "narrow",
            hour12: true
          })}
          <input
            name={props.name}
            id={v.date}
            hidden
            type="radio"
            value={new Date(v.date).toISOString()}
            onChange={props.onChange}
          />
        </label>
      );
    });
  }, [
    props.data,
    props.name,
    props.selectedDate,
    props.onChange,
    props.allowBooking,
    props.bbtFlag,
    selectedLanguage?.language_code
  ]);

  const _renderTitle = React.useMemo(() => {
    if (!props.header) {
      return;
    }
    const headerDate = new Date(props.header);
    const todayDate = new Date();
    if (
      headerDate.getUTCMonth() == todayDate.getUTCMonth() &&
      headerDate.getUTCDate() == todayDate.getUTCDate()
    ) {
      return (
        <div className="calendar__title">{translate("search__doctor_card__reservation_today")}</div>
      );
    }
    if (
      headerDate.getUTCMonth() == todayDate.getUTCMonth() &&
      headerDate.getUTCDate() == todayDate.getUTCDate() + 1
    ) {
      return (
        <div className="calendar__title">
          {translate("search__doctor_card__reservation_tomorrow")}
        </div>
      );
    }
    return (
      <div className="calendar__title">
        {/* ar-eg or en-uk */}

        {headerDate.toLocaleDateString(
          `${selectedLanguage?.language_code}-${selectedCountry?.code2}`,
          {
            weekday: "short",
            day: "2-digit",
            month: "2-digit"
          }
        )}
      </div>
    );
  }, [props.header, selectedLanguage?.language_code, selectedCountry?.code2, translate]);

  return (
    <div className="calendar">
      {_renderTitle}
      <div className="divider" style={{ marginTop: "1.2rem" }}></div>
      <div className="calendar__body" style={!userToken ? { height: "18.2rem" } : {}}>
        {_renderItems}
      </div>
      {userToken && !props.alreadyBooked ? (
        <div className="calendar__action">
          <Button.ButtonSmall
            action={submit}
            text={translate("search__doctor_card__reservation_now")}
            textClasses="typo--s typo--bold"
          />
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default Calendar;
