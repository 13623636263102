import * as React from "react";
import EarPlugIcon from "../../assets/ear-plug.svg";
import CareServiceIcon from "../../assets/care-service.svg";
import MedBoxIcon from "../../assets/med-box.svg";
import CameraIcon from "../../assets/camera.svg";
import ReviewIcon from "../../assets/review.svg";
import TimeIcon from "../../assets/time.svg";
import EntranceIcon from "../../assets/business.svg";
import LocationIcon from "../../assets/location.svg";
import FinanceIcon from "../../assets/finance.svg";
import MedListIcon from "../../assets/med-list.svg";

import IconLabel from "../../components/iconLabel";
import Reservation from "../../components/reservation";
import Button from "../../components/button";
import Carousel from "../../components/carousel";
import Review from "../../components/review";

import Stars from "../../components/stars";

import useDoctorDetails from "./useDoctorDetails";
import { useAppSelector } from "../../redux";
import { clsx } from "../../utils/clsx";

const Details: React.FC<any> = () => {
  const {
    selectedDoctor,

    language,
    insuranceCompanies,
    translate,
    DoctorListForm,
    onInputChange,
    onInsuranceTagPress,
    reviewForm,
    setReviewForm,
    onInputReviewChange,
    onReviewCancel,
    onReviewUpdateSubmit,
    onReviewAddSubmit,
    user,
    doctorReviews,
    onReviewDelete,
    onReviewDeleteSubmit,
    onReviewEdit,
    errors
  } = useDoctorDetails();
  const loading = useAppSelector((state) => state.loading);

  const _renderDoctorReviews = React.useMemo(() => {
    return doctorReviews?.map((r, i) => {
      return (
        <div className="detail-doctor__info__section__body__comment" key={r._id ?? i}>
          <Review
            overrideContainerStyles={{ backgroundColor: "var(--color-gray-7)", border: "0px" }}
            review={{
              action: reviewForm.type,
              onDelete: onReviewDeleteSubmit,
              show: reviewForm.reviewId == r._id,
              name: "comment",
              onChange: onInputReviewChange,
              value: reviewForm.comment,
              onCancel: onReviewCancel,
              onSubmit: onReviewAddSubmit,
              shouldUpdate: reviewForm.reviewId == "" && r.patient._id == user?.data?._id,
              onEditClick: () => onReviewEdit(r),
              onDeleteClick: () => onReviewDelete(r._id),
              onUpdate: onReviewUpdateSubmit
            }}
            language={language}
            comment={r.comment}
            date={new Date(r.createdAt)}
            name={r.patient.name?.first[language] + " " + r.patient.name?.father[language]}
            rating={{
              noOfStars: reviewForm.reviewId == r._id ? reviewForm.rate : r.rate_stars,
              selectAction: (rating) => {
                if (reviewForm.reviewId == r._id && user.data?._id == r.patient._id) {
                  setReviewForm((old) => {
                    return { ...old, rate: rating + 1 };
                  });
                }
              }
            }}
          />
          {r.patient._id == user?.data?._id && errors.comment.length ? (
            <div>{errors.comment}</div>
          ) : (
            <></>
          )}
        </div>
      );
    });
  }, [reviewForm, doctorReviews, user.data, language, user.reviews, errors]);

  const _renderInsuranceCompanies = React.useMemo(() => {
    return insuranceCompanies.map((d, i) => (
      <div key={d._id ?? i} className="detail-doctor__info__section__body__tag">
        <Button.ButtonTag text={d.name} action={onInsuranceTagPress(d._id ?? "")} />
      </div>
    ));
  }, [language, insuranceCompanies]);

  return (
    <div className="detail-doctor">
      <div className="detail-doctor__info">
        <div className="detail-doctor__info__profile">
          <div className="detail-doctor__info__profile__img">
            <img src={selectedDoctor?.logo_url} alt="asd" />
          </div>
          <div className="detail-doctor__info__profile__info">
            <div className="detail-doctor__info__profile__info__title">{selectedDoctor?.name}</div>
            <div className="detail-doctor__info__profile__info__sub-title"></div>
            <div className="detail-doctor__info__profile__info__description"></div>
            <div className="detail-doctor__info__profile__info__rate">
              <div className="detail-doctor__info__profile__info__rate__star">
                <Stars
                  noOfStars={selectedDoctor?.rate_stars}
                  selectAction={(rateing: number) => {
                    if (user?.reviews?.length) {
                      return;
                    }
                    setReviewForm({ comment: "", reviewId: "new", rate: rateing + 1, type: "add" });
                  }}
                />
              </div>
              <div className="detail-doctor__info__profile__info__rate__detail">
                {`${translate("search__doctor_card__evaluation")} ${
                  selectedDoctor?.rate_count
                } ${translate("search__doctor_card__evaluation_phrase")}`}
              </div>
            </div>
            <div className="detail-doctor__info__profile__info__review">
              <Review
                review={{
                  action: reviewForm.type,
                  show: reviewForm.reviewId == "new",
                  name: "comment",
                  onChange: onInputReviewChange,
                  value: reviewForm.comment,
                  onCancel: onReviewCancel,
                  onSubmit: onReviewAddSubmit,
                  onDelete: onReviewDeleteSubmit,
                  // shouldUpdate
                  onUpdate: onReviewUpdateSubmit
                }}
                comment="الدكتور شاطر جدا ، والعيادة ضريفه - منظمة ، يواجد جراج خاص للسيدات ، التعامل في غابـة الواد والاحترام"
                date={new Date("2021-02-24T15:39:00.000Z")}
                name="محمود ح"
                rating={{
                  noOfStars: reviewForm.rate,
                  selectAction: (rating) => {
                    if (reviewForm.reviewId == "new") {
                      setReviewForm((old) => {
                        return { ...old, rate: rating + 1 };
                      });
                    }
                  }
                }}
                overrideContainerStyles={{ backgroundColor: "var(--color-gray-7)", border: "0px" }}
              />
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="detail-doctor__info__section">
          <div className="detail-doctor__info__section__title">
            <IconLabel
              svg={{
                icon: EarPlugIcon,
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-blue-1)"
              }}
              text={translate("details__info_doctor")}
            />
          </div>
          <div className="detail-doctor__info__section__body">
            <div className="typo--s">{selectedDoctor?.description}</div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="detail-doctor__info__section">
          <div className="detail-doctor__info__section__title">
            <IconLabel
              svg={{
                icon: CareServiceIcon,
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-blue-1)"
              }}
              text={translate("details__info_clinic_service")}
            />
          </div>
          <div className="detail-doctor__info__section__body">
            <div className="detail-doctor__info__section__body__tag-container">
              <div className="detail-doctor__info__section__body__tag">
                <Button.ButtonTag text="there" action={() => {}} />
              </div>
              <div className="detail-doctor__info__section__body__tag">
                <Button.ButtonTag text="is" action={() => {}} />
              </div>
              <div className="detail-doctor__info__section__body__tag">
                <Button.ButtonTag text="no" action={() => {}} />
              </div>
              <div className="detail-doctor__info__section__body__tag">
                <Button.ButtonTag text="api" action={() => {}} />
              </div>
              <div className="detail-doctor__info__section__body__tag">
                <Button.ButtonTag text="for" action={() => {}} />
              </div>
              <div className="detail-doctor__info__section__body__tag">
                <Button.ButtonTag text="that" action={() => {}} />
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="detail-doctor__info__section">
          <div className="detail-doctor__info__section__title">
            <IconLabel
              svg={{
                icon: MedBoxIcon,
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-blue-1)"
              }}
              text={translate("details__info_insurance_companies")}
            />
          </div>
          <div className="detail-doctor__info__section__body">
            <div className="detail-doctor__info__section__body__tag-container">
              {_renderInsuranceCompanies}
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="detail-doctor__info__section">
          <div className="detail-doctor__info__section__title">
            <IconLabel
              svg={{
                icon: CameraIcon,
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-blue-1)"
              }}
              text={translate("details__info_clinic_images")}
            />
          </div>
          <div className="detail-doctor__info__section__body">
            <Carousel images={[]} />
          </div>
        </div>
        <div className="divider"></div>
        <div className="detail-doctor__info__section">
          <div className="detail-doctor__info__section__title">
            <IconLabel
              svg={{
                icon: ReviewIcon,
                width: "2.4rem",
                height: "2.4rem",
                color: "var(--color-blue-1)"
              }}
              text={translate("details__info_reviews")}
            />
          </div>
          <div
            className={clsx("detail-doctor__info__section__body", loading.user ? "loading" : "")}
          >
            {_renderDoctorReviews}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "detail-doctor__reservation",
          loading.doctor?.includes(selectedDoctor?._id) ? "loading" : ""
        )}
      >
        <Reservation
          date={{
            title: translate("details__reservation_calendar_title"),
            calendar: {
              patientId: user?.data?._id,
              allowBooking: selectedDoctor?.allowBooking,
              bbtflag: selectedDoctor?.bbtFlag,
              currentLanguage: language,
              data: selectedDoctor?.sessions ?? [],
              doctorId: selectedDoctor?._id,
              onChange: onInputChange,
              selectedDates: (DoctorListForm as any)?.[selectedDoctor?._id]
            }
          }}
          details={{
            entrance: {
              svg: {
                color: "var(--color-blue-1)",
                height: "2.4rem",
                icon: EntranceIcon,
                width: "2.4rem"
              },
              text: selectedDoctor?.bbtFlag
                ? translate("details__reservation_exact_time")
                : translate("details__reservation_range_time"),
              textColor: "var(--color-gray-3)"
            },
            location: {
              svg: {
                color: "var(--color-blue-1)",
                height: "2.4rem",
                icon: LocationIcon,
                width: "2.4rem"
              },
              text: selectedDoctor?.address,
              textColor: "var(--color-gray-3)"
            },
            price: {
              subTitle: selectedDoctor?.consult_fees,
              svg: {
                color: "var(--color-blue-1)",
                height: "4.8rem",
                icon: FinanceIcon,
                width: "5.7rem"
              },
              title: translate("details__reservation_price")
            },
            time: {
              subTitileColor: "var(--color-status-correct)",
              subTitle: selectedDoctor?.workingHours,
              svg: {
                color: "var(--color-status-correct)",
                height: "4.8rem",
                icon: TimeIcon,
                width: "4.7rem"
              },
              title: translate("details__reservation_time"),
              titleColor: "var(--color-status-correct)"
            },
            type: {
              subTitle: "",
              svg: {
                color: "var(--color-blue-1)",
                height: "4.6rem",
                icon: MedListIcon,
                width: "4.3rem"
              },
              title: translate("details__reservation_type")
            }
          }}
          enrancePhrase=""
          locationPhrase=""
          title={translate("details__reservation__main_title")}
        />
      </div>
    </div>
  );
};

export default Details;
