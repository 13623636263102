import * as React from "react";

import Input from "../../components/input";
import Button from "../../components/button";
import Svg from "../../components/svg";

import LogoIcon from "../../assets/logo-2.svg";
import CallIcon from "../../assets/call.svg";

import CopyRight from "../../components/copyRight";
import { Link } from "react-router-dom";

import useResetPassword from "./useResetPassword";

const ResetPassword: React.FC<any> = () => {
  // const { onInputChange, translate, history, errors, submit } = useResetPassword();
  const { translate, onInputChange, errors, submit } = useResetPassword();

  return (
    <div className="login">
      <div className="login__logo mirror">
        <img src={LogoIcon} alt="Clinio" />
      </div>
      <div className="login__form">
        <div className="login__form__main-title">
          {translate("reset_password__form__main_title")}
        </div>
        <div className="login__form__sub-title">{translate("reset_password__form__sub_title")}</div>
        <div className="login__form__input-container">
          <div className="login__form__input-container__item">
            <Input.InputNormal
              name="password"
              type="password"
              autoComplete="new-paswords"
              onchange={(event) => {
                onInputChange(event);
              }}
              placeholder={translate("login__form__password__placeholder")}
              adornment={<div> </div>}
            />
          </div>
          <div className="login__form__input-container__item-error">{errors.password}</div>
          <div className="login__form__input-container__item">
            <Input.InputNormal
              name="confirmPassword"
              autoComplete="new-passwords"
              type="password"
              onchange={onInputChange}
              placeholder={translate("register__form__confirm_password__placeholder")}
            />
          </div>
          <div className="login__form__input-container__item-error">{errors.confirmPassword}</div>
        </div>

        <div className="login__form__actions">
          <div className="login__form__actions__button">
            <Button.ButtonWide action={submit} text={translate("reset_password__form__save")} />
          </div>
        </div>
        <div className="login__form__main-catch-phrase" style={{ paddingTop: "11rem" }}>
          {translate("home__search__main_catch_phrase")}
        </div>
        <div className="login__form__sub-catch-phrase">
          {translate("home__search__sub_catch_phrase")}
        </div>
        <div className="login__form__contact">
          {translate("clinio__contact_number")}
          <div className="login__form__contact__icon">
            <Svg width="2rem" height="2rem" color="var(--color-gray-3)" icon={CallIcon} />
          </div>
        </div>
      </div>
      <div className="login__copy-right">
        <CopyRight />
      </div>
    </div>
  );
};

export default ResetPassword;
