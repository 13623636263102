import React from "react";
import Button from "../../components/button";
import Input from "../../components/input";
import ArrowLeftIcon from "../../assets/arrow-left.svg";
import MedListIcon from "../../assets/med-list.svg";
import Logo from "../../assets/logo-2.svg";
import crossIcon from "../../assets/delete.svg";
import LocationIcon from "../../assets/location.svg";
import EarPlugIcon from "../../assets/ear-plug.svg";

import { useHome } from "../../pages/home/useHome";
import SelectList from "../../components/selectlist";
import Modal from "../../components/modal";
import List from "../../components/list";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { clsx } from "../../utils/clsx";

interface InputPreProps {
  text: string;
  icon: string;
}

const InputPre: React.FC<InputPreProps> = React.memo(({ text, icon }) => (
  <Input.InputPre
    svg={{
      color: "var(--color-gray-5)",
      height: "2.4rem",
      width: "2.4rem",
      icon
    }}
    text={text}
  />
));

const searchHeader: React.FC = () => {
  const {
    form,
    onMultiSelectChange,
    onInputChange,
    selectedSpecialities,
    specialitiesPage,
    setSpecialitesPage,
    selectedRegion,
    regionData,
    selectedAreas,
    onSwitchChange,
    specialitiesData,
    areasPage,
    setAreasPage,
    areaData,
    submitForm,
    query,
    resetSpecialties,
    resetLocation,
    translate
  } = useHome();
  const loading = useAppSelector((state) => state.loading);
  return (
    <div className={clsx("searchHeader", loading.doctors ? "loading" : "")}>
      <Link to="/home" className="searchHeader__logo">
        <img src={Logo} alt="Clinio" />
      </Link>
      <form name="searchHeader" id="searchHeader" className="searchHeader__searchControls">
        <div className="searchHeader__inputs">
          <div className="searchHeader__input">
            <Input.InputNormal
              onfocus={() => {
                (document.getElementsByClassName("modal-button")[0] as any).click();
              }}
              name="specialityName"
              value={selectedSpecialities?.map((s) => s.name).join(",")}
              pre={<InputPre text={translate("home__search__specialities")} icon={MedListIcon} />}
              adornment={
                <div style={{ display: "flex" }}>
                  {selectedSpecialities?.length ? (
                    <Button.ButtonRounded
                      svg={{
                        width: "2.4rem",
                        height: "2.4rem",
                        color: "var(--color-status-delete)",
                        reversable: true,
                        icon: crossIcon
                      }}
                      overrideStyles={{ background: "var(--color-gray-8)" }}
                      action={resetSpecialties}
                    />
                  ) : (
                    <></>
                  )}

                  <Modal
                    modalTitle=""
                    ModalBody={() => {
                      return (
                        <SelectList
                          onPageChange={setSpecialitesPage}
                          page={specialitiesPage}
                          name="specialityIds"
                          selectedIds={selectedSpecialities?.map((s) => s._id ?? "")}
                          onChange={onMultiSelectChange}
                          overrideButtonStyle={{ border: "1px solid var(--color-gray-6)" }}
                          overrideContainerStyle={{ backgroundColor: "transparent" }}
                          header={translate("home__search__specialities__search_header")}
                          overrideHeaders={(value, classes) => {
                            return (
                              <div style={{ paddingLeft: "1.2rem", position: "relative" }}>
                                <div
                                  style={{ position: "absolute", left: "57.5rem", top: "-4.5rem" }}
                                >
                                  <Button.ButtonRounded
                                    svg={{
                                      width: "2.4rem",
                                      height: "2.4rem",
                                      color: "var(--color-status-delete)",
                                      reversable: true,
                                      icon: crossIcon
                                    }}
                                    overrideStyles={{ background: "var(--color-gray-8)" }}
                                    action={() => {
                                      (document.getElementsByClassName(
                                        "modal__background"
                                      )[0] as any).click();
                                    }}
                                  />
                                </div>
                                <Input.InputSearch
                                  name="search"
                                  value={form.search ?? ""}
                                  onchange={onInputChange}
                                  placeholder={translate(
                                    "home__search__specialities__search_input__placeholder"
                                  )}
                                />
                                <div
                                  className={classes}
                                  style={{ paddingTop: "2.4rem", color: "var(--color-gray-3)" }}
                                >
                                  {value}
                                </div>
                              </div>
                            );
                          }}
                          columns={3}
                          rows={8}
                          data={specialitiesData}
                        />
                      );
                    }}
                    ModalButton={() => {
                      return (
                        <Button.ButtonRounded
                          overrideStyles={{ background: "var(--color-yellow-1)" }}
                          svg={{
                            width: "0.5rem",
                            height: "0.8rem",
                            color: "var(--color-gray-8)",
                            reversable: true,
                            icon: ArrowLeftIcon
                          }}
                          action={() => {}}
                        />
                      );
                    }}
                  />
                </div>
              }
              placeholder={translate("home__search__specialities__placeholder")}
              onchange={() => {}}
              // overrideStyles={{ minWidth: "32.9rem" }}
            />
          </div>
          <div className="searchHeader__input">
            <Input.InputNormal
              name="location"
              onfocus={() => {
                (document.getElementsByClassName("modal-button")[1] as any).click();
              }}
              value={
                (selectedRegion?.name ?? "") +
                (selectedAreas?.length
                  ? " " + selectedAreas?.map((a) => a.name ?? "").join(",")
                  : "")
              }
              pre={<InputPre text={translate("home__search__location")} icon={LocationIcon} />}
              adornment={
                <div style={{ display: "flex" }}>
                  {selectedRegion?.name || selectedAreas?.length ? (
                    <Button.ButtonRounded
                      svg={{
                        width: "2.4rem",
                        height: "2.4rem",
                        color: "var(--color-status-delete)",
                        reversable: true,
                        icon: crossIcon
                      }}
                      overrideStyles={{ background: "var(--color-gray-8)" }}
                      action={resetLocation}
                    />
                  ) : (
                    <></>
                  )}
                  <Modal
                    modalTitle=""
                    ModalBody={() => {
                      return (
                        <div style={{ display: "flex", position: "relative" }}>
                          <div style={{ position: "absolute", left: "64.5rem", top: "-2.5rem" }}>
                            <Button.ButtonRounded
                              svg={{
                                width: "2.4rem",
                                height: "2.4rem",
                                color: "var(--color-status-delete)",
                                reversable: true,
                                icon: crossIcon
                              }}
                              overrideStyles={{ background: "var(--color-gray-8)" }}
                              action={() => {
                                (document.getElementsByClassName(
                                  "modal__background"
                                )[1] as any).click();
                              }}
                            />
                          </div>
                          <div className="form__list">
                            <List
                              name="regionId"
                              data={regionData}
                              onSelect={onInputChange}
                              selectedItemId={selectedRegion?._id ?? ""}
                            />
                          </div>
                          <div className="form__select-list">
                            <SelectList
                              overrideContainerStyle={{ minWidth: "40.8rem" }}
                              header={translate("home__search__location__search_header")}
                              page={areasPage}
                              onPageChange={setAreasPage}
                              columns={2}
                              rows={10}
                              data={areaData}
                              name="areaIds"
                              selectedIds={selectedAreas?.map((a) => a._id ?? "")}
                              onChange={onMultiSelectChange}
                            />
                          </div>
                        </div>
                      );
                    }}
                    ModalButton={() => {
                      return (
                        <Button.ButtonRounded
                          overrideStyles={{ background: "var(--color-yellow-1)" }}
                          svg={{
                            width: "0.5rem",
                            height: "0.8rem",
                            color: "var(--color-gray-8)",
                            reversable: true,
                            icon: ArrowLeftIcon
                          }}
                          action={() => {}}
                        />
                      );
                    }}
                  />
                </div>
              }
              placeholder={translate("home__search__location__placeholder")}
              onchange={() => {}}
            />
          </div>
          <div className="searchHeader__input">
            <Input.InputNormal
              name="searchDoctorName"
              defaultValue={query.search}
              pre={<InputPre text={translate("home__search__doctor_name")} icon={EarPlugIcon} />}
              // adornment={}
              placeholder={translate("home__search__doctor_name__placeholder")}
              onchange={onInputChange}
            />
          </div>
          <div className="searchHeader__action">
            <Button.ButtonWide
              text={translate("home__search__start")}
              action={() => submitForm()}
            />
          </div>
        </div>
        <div className="searchHeader__selections">
          <br />
          <div>
            <label className="searchHeader__switchLabel">
              {translate("home__search__home_visite")}
            </label>
            <Input.InputSwitch
              name="home_visite"
              checked={query.home_visite ?? false}
              onChange={onSwitchChange}
            />
          </div>
          <div>
            <label className="searchHeader__switchLabel">
              {translate("home__search__online_consult")}
            </label>
            <Input.InputSwitch
              name="online_consult"
              checked={query.online_consult ?? false}
              onChange={onSwitchChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default searchHeader;
