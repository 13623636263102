import Dummy from "../pages/home";
import { SearchPage } from "../pages/search";
import DoctorDetailsPage from "../pages/doctorDetails";
import LoginPage from "../pages/login";
import Register from "../pages/register";
import ForgetPassword from "../pages/forgetPassword";
import ResetPassword from "../pages/resetPassword";
interface IRoutes {
  route: string;
  component: React.FC;
  searchHeader?: boolean;
}

export const routes: IRoutes[] = [
  { route: "/", component: Dummy },
  { route: "/search", component: SearchPage, searchHeader: true },
  { route: "/details/:id", component: DoctorDetailsPage, searchHeader: true },
  { route: "/login", component: LoginPage },
  { route: "/register", component: Register },
  { route: "/forgetpassword", component: ForgetPassword },
  { route: "/resetpassword", component: ResetPassword }
];
