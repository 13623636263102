import React from "react";

import { Pagination } from "../../components/pagination";
import AccordionList from "../../components/accordionList";
import CardDoctor from "../../components/cardDoctor";
import Input from "../../components/input";

import useSearch from "./useSearch";
import { useHistory } from "react-router-dom";
import { CheckBox } from "../../components/checkbox";
import { useAppSelector } from "../../redux";
import { clsx } from "../../utils/clsx";
import dropdown from "../../components/dropdown";

export type SearchPageProps = {};

const SearchPage: React.FC<SearchPageProps> = () => {
  // const language = useAppSelector(useCallback((state) => state.meta.language, []));

  const {
    totalNumOfDoctors,
    setCurrentPage,
    doctorsData,
    onCardDoctorClick,
    query,
    // DoctorListForm, // to handle the selected dates from doctors card,
    filterForm,
    onMultiSelectFilterChange,

    language,
    selectedLanguage,

    selectedCountry,

    insurancesData,
    // selectedInsurances,
    onInputChange,
    onInputFilterChange,

    doctorLevelData,

    subSpecialityData,

    translate
  } = useSearch();
  const patient = useAppSelector((state) => state.user.data);
  const history = useHistory();
  const loading = useAppSelector((state) => state.loading);
  const _renderDoctors = React.useMemo(() => {
    if (!doctorsData) {
      return <h1>NO DATA</h1>;
    }

    // data keep changing ( backend is unstable ) i had to do this
    return doctorsData.map((doc: any, i) => (
      <React.Fragment key={doc._id + doc.clinic ?? i}>
        <CardDoctor
          loading={loading.doctor?.includes(doc._id)}
          onCardClick={() => {
            onCardDoctorClick(query.pageNumber ?? 0, doc._id);
            history.push(`/details/${doc._id}`);
          }}
          patientId={patient?._id}
          onChange={onInputChange}
          doctorId={doc._id}
          allowBooking={doc.allowOnlineBooking}
          bbtFlag={doc.bbtFlag}
          details={{
            description: doc.description,
            entrance: doc.bbtFlag
              ? translate("search__doctor_card__entrance_exact")
              : translate("search__doctor_card__entrance_range"),
            location: doc.address,
            price: doc.consult_fees,
            time: doc.workingHours
          }}
          rating={{
            noOfUsers: doc.rating_count,
            noOfStars: doc.rating_stars,
            selectAction: () => {}
          }}
          img={(doc as any).logo_url}
          title={doc.name}
          // subtitle={"details"}
          language={language}
          timeTable={(doc as any).sessions ?? []}
        />
      </React.Fragment>
    ));
  }, [doctorsData, query.pageNumber, onCardDoctorClick, language, onInputChange]);

  const _renderAccordionList = React.useMemo(() => {
    return (
      <AccordionList
        loading={loading.doctors}
        data={[
          {
            title: translate("search__filter__header__title_doc_level"),
            formName: "doctorLevel",
            selectedData: filterForm.doctorLevel,
            data: doctorLevelData
          },
          {
            title: translate("search__filter__header__title_sub_spec"),
            formName: "subSpecialities",
            selectedData: filterForm.subSpecialities,
            data: subSpecialityData
          },
          {
            title: translate("search__filter__header__title_gender"),
            formName: "gender",
            selectedData: [],
            data: [
              {
                id: translate("search__filter__header__title_gender_male"),
                name: "gender",
                value: "male"
              },
              {
                id: translate("search__filter__header__title_gender_female"),
                name: "gender",
                value: "female"
              },
              {
                id: translate("search__filter__header__title_gender_all"),
                name: "gender",
                value: ""
              }
            ],
            overrideItems: (data) => {
              return (
                <CheckBox
                  onchange={onInputFilterChange}
                  name={data.accordionItem.name}
                  title={data.accordionItem.id}
                  value={data.accordionItem.value as any}
                  checked={
                    filterForm.gender == data.accordionItem.value ||
                    (filterForm.gender == "" && data.accordionItem.value == "both")
                  }
                  id={data.accordionItem.id}
                />
              );
            }
          },
          {
            title: translate("search__filter__header__title_insurance_companies"),
            formName: "insurances",
            selectedData: filterForm.insurances,
            data: insurancesData
          },
          {
            title: translate("search__filter__header__title_price_range"),
            formName: "consult_fees",
            selectedData: [filterForm.maxRange],
            data: [{ id: "_maxRange", name: "maxRange", value: "2000" }],
            overrideItems: (data) => {
              return (
                <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                  0
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%"
                    }}
                  >
                    <label
                      className="checkbox__label"
                      style={{ marginRight: "auto", marginLeft: "auto" }}
                    >
                      {/* {data.accordionItem.name}:{data.accordionData.selectedData[data.index]} */}
                      <CheckBox
                        onchange={onMultiSelectFilterChange}
                        name={"activePrice"}
                        title={`${
                          filterForm.activePrice?.includes(data.accordionItem.name)
                            ? "disable"
                            : "enabled"
                        } ${data.accordionItem.name}:${
                          data.accordionData.selectedData[data.index] ?? "N/A"
                        }`}
                        value={data.accordionItem.name}
                        checked={filterForm.activePrice?.includes(data.accordionItem.name)}
                        id={data.accordionItem.id}
                      />
                    </label>
                    <input
                      disabled={filterForm.activePrice?.includes(data.accordionItem.name)}
                      key={data.accordionItem.id}
                      type="range"
                      name={data.accordionItem.name}
                      id={data.accordionItem.id}
                      min={0}
                      max={2000}
                      defaultValue={data.accordionData.selectedData[data.index]}
                      onMouseUp={onInputFilterChange as any}
                    />
                  </div>
                  2000
                </div>
              );
            }
          }
        ]}
        onChange={onMultiSelectFilterChange}
      />
    );
  }, [
    doctorLevelData,
    subSpecialityData,
    insurancesData,
    onMultiSelectFilterChange,
    onInputChange,
    filterForm,
    translate,
    loading.doctors
  ]);
  return (
    <main className="search">
      {_renderAccordionList}
      <div className={clsx("search__content", loading.doctors ? "loading" : "")}>
        <div className="search__arrangement">
          <div className="search__arrangement__side">
            <h4 className="search__arrangement__title">{translate("search__results_count")}</h4>
            <h6 className="search__arrangement__count">
              <div className="" style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
                {totalNumOfDoctors.toLocaleString(`${language}-${selectedCountry?.code2 ?? "eg"}`)}
              </div>
              <div className="">{translate("doctor")}</div>
            </h6>
          </div>
          <div className="search__arrangement__side">
            <h4 className="search__arrangement__sort">{translate("search__sort_by")}</h4>
            <h6 className="search__arrangement__count">
              <Input.InputNormal
                name="order"
                adornment={
                  <dropdown.DropdownLabels
                    data={[
                      { name: "1", _id: "1", value: "1" },
                      { name: "2", _id: "2", value: "2" },
                      { name: "3", _id: "3", value: "3" }
                    ]}
                    formName="sort"
                    onChange={() => {}}
                    selectedItem="1"
                    overrideContentCss={
                      selectedLanguage?.r2l ? { left: "-2rem" } : { left: "-7rem" }
                    }
                  />
                }
                value="1"
                onchange={() => {}}
                defaultValue="1"
              />
            </h6>
          </div>
        </div>
        <div className="search__result">{_renderDoctors}</div>
        <div className="search__pagination">
          {/* {console.log(query.pageSize, totalNumOfDoctors, query.pageNumber)} */}
          <Pagination
            language={"lang-" + language}
            // pagesCount={1}
            currentPage={(query.pageNumber ?? 0) + 1}
            pagesCount={query.pageSize ? Math.ceil(totalNumOfDoctors / query.pageSize) : 1}
            // currentPage={query.pageNumber ?? 1}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default SearchPage;
