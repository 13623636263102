import { LabelsActionTypes } from "../../constants/actionTypes";

export const setLabelsData = (lang: string, data: { [key: string]: string }) => {
  return {
    type: LabelsActionTypes.SET_LABELS_DATA,
    payload: {
      lang,
      data
    }
  };
};
