import React, { FC, Fragment } from "react";

import Svg from "../svg";
import SplitterIcon from "../../assets/breadcrumb-splitter.svg";
import BreadcrumbHomeIcon from "../../assets/breadcrumb-home";
import { Link } from "react-router-dom";

export interface BreadcrumbProps {
  title: string;
  locations?: { href?: string; title: string }[];
}

const Splitter: FC = React.memo(() => (
  <div className="breadcrumb__splitter">
    <Svg
      reversable
      icon={SplitterIcon}
      color="var(--color-gray-5)"
      width="0.3rem"
      height="0.6rem"
    />
  </div>
));

const Breadcrumb: FC<BreadcrumbProps> = ({ title, locations }) => {
  return (
    <div className="breadcrumb">
      <BreadcrumbHomeIcon />
      <Link to="/" className="breadcrumb__title">
        {title}
      </Link>
      <Splitter />
      {locations?.map((location, index) => (
        <Fragment key={location.title ?? index}>
          <Link to={location.href} className="breadcrumb__subLink">
            {location.title}
          </Link>
          {index !== locations.length - 1 && <Splitter />}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
