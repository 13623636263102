import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks";
import useForm from "../../hooks/useForm";

import isEmail from "validator/es/lib/isEmail";

import useErrors from "../../hooks/useErrors";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux";
import { userLoginDispatch } from "../../redux/middlewares/userMiddlewares";

export const useLogin = () => {
  const { translate } = useTranslation();
  const history = useHistory();
  const { form, onInputChange } = useForm<{ email: string; password: string }>({
    email: "",
    password: ""
  });
  const { errors, assignError, clearError } = useErrors<{ email: string; password: string }>({
    email: "",
    password: ""
  });

  const validateOnEmail = React.useCallback(async () => {
    if (!isEmail(form.email)) {
      assignError("email", translate("login__form__email_error"));
      return false;
    }
    clearError("email");
    return true;
  }, [form.email, translate]);

  const validateOnPassword = React.useCallback(async () => {
    if (form.password.length < 6) {
      assignError("password", translate("login__form__password_error"));
      return false;
    }
    clearError("password");
    return true;
  }, [form.password, translate]);

  const { data: userData, token }: any = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (token && userData) {
      history.push("/home");
    }
  }, [token, userData]);

  const submit = React.useCallback(() => {
    Promise.all([validateOnEmail(), validateOnPassword()]).then((r) => {
      if (r.every((result) => result == true)) {
        dispatch(
          userLoginDispatch({
            email: form.email,
            password: form.password
          })
        );
      }
    });
  }, [form, validateOnPassword, validateOnEmail]);

  return {
    form,
    onInputChange,

    errors,

    history,

    translate,

    submit
  };
};
