import { AppDispatch } from "..";
import { DoctorLevelApi } from "../../api/doctorLevelApi";
import { setDoctorQuery } from "../actions/doctorActions";
import { setDoctorLevelData, setSelectedDoctorLevels } from "../actions/doctorLevelActions";
import { setLoading } from "../actions/loadingActions";

export const getDoctorLevelsDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ filter: true }));
    const response = await DoctorLevelApi.getAll().catch((r) => r);
    if (response.status != 200) {
      return;
    }
    dispatch(setDoctorLevelData(response.data));
    dispatch(setLoading({ filter: false }));
  };
};

export const setSelectedDoctorLevelsDispatch = (doctorLevelIds: string[]) => {
  return async (dispatch: AppDispatch) => {
    // debugger;
    dispatch(setDoctorQuery({ doctorLevel: doctorLevelIds }));
    dispatch(setSelectedDoctorLevels(doctorLevelIds));
  };
};
