import { AppDispatch } from "..";
import { CountriesApi } from "../../api/contriesApi";
import { resetSelectedCountry, setCountyData, setSelectedCountry } from "../actions/countryActions";
import { setDoctorQuery } from "../actions/doctorActions";
import { setLoading } from "../actions/loadingActions";

export const getCountriesDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ country: true, doctors: true, region: true, area: true, labels: true }));
    const response = await CountriesApi.getAll().catch((r) => r);
    if (response.status != 200) {
      return;
    }
    dispatch(setCountyData(response.data.data ?? []));
    const selectedCountry = response.data.data?.find(
      (c: any) => c.code3 == "EGY" || c.code2 == "EGY"
    )?._id;
    dispatch(setSelectedCountry(selectedCountry));
    dispatch(setDoctorQuery({ sys_country_code3: selectedCountry }));
    dispatch(
      setLoading({ country: false, doctors: false, region: false, area: false, labels: false })
    );
  };
};
export const setSelectedCountryDispatch = (countryId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ country: true, doctors: true, region: true, area: true, labels: true }));
    dispatch(setSelectedCountry(countryId));
    dispatch(setDoctorQuery({ sys_country_code3: countryId }));
    dispatch(
      setLoading({ country: false, doctors: false, region: false, area: false, labels: false })
    );
  };
};

export const resetSelectedCountryDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedCountry());
    dispatch(setDoctorQuery({ sys_country_code3: "" }));
  };
};
