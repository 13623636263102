import React from "react";
import { useTranslation } from "../../hooks";
import useForm from "../../hooks/useForm";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
  getAreaDispatch,
  resetSelectedAreasDispatch,
  setSelectedAreasDispatch
} from "../../redux/middlewares/areaMiddlewares";

import {
  getRegionsDispatch,
  resetSelectedRegionDispatch,
  setSelectedRegionDispatch
} from "../../redux/middlewares/regionMiddlewares";

import {
  getSpecialitiesDispatch,
  resetSelectedSpecialitiesDispatch,
  setSelectedSpecalitiesDispatch
} from "../../redux/middlewares/specialityMiddlewares";
import {
  getDoctorsDispatch,
  setDoctorQueryDispatch
} from "../../redux/middlewares/doctorsMiddlewares";
import { setDirectionDispatch } from "../../redux/middlewares/metaMiddlewares";
import { useHistory } from "react-router-dom";

export const useHome = () => {
  const { direction } = useAppSelector((state) => state.meta);
  const { translate, language } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const query = useAppSelector((state) => state.doctors.query);
  // selectors
  const { countryData, selectedCountry } = useAppSelector(
    React.useCallback(
      (state) => {
        return {
          countryData: state.countries.data.map(({ _id, name }) => ({
            _id,
            name: name?.[language ?? "en"] ?? name?.["en"]
          })),
          selectedCountry: {
            ...state.countries.selectedCountry,
            name:
              state.countries.selectedCountry?.name?.[language ?? "en"] ??
              state.countries.selectedCountry?.name?.["en"]
          }
        };
      },
      [language]
    )
  );

  const { areaData, selectedAreas } = useAppSelector(
    React.useCallback(
      (state) => {
        return {
          areaData: state.areas.data.map(({ _id, name }) => ({
            _id,
            name: name?.[language ?? "en"] ?? name?.["en"]
          })),
          selectedAreas: state.areas.selectedAreas?.map((area) => {
            return {
              ...area,
              name: area.name?.[language ?? "en"] ?? area.name?.["en"]
            };
          })
        };
      },
      [language]
    )
  );

  const { regionData, selectedRegion } = useAppSelector(
    React.useCallback(
      (state) => {
        return {
          regionData: state.regions.data.map(({ _id, name }) => ({
            _id,
            name: name?.[language ?? "en"] ?? name?.["en"]
          })),
          selectedRegion: {
            ...state.regions.selectedRegion,
            name:
              state.regions.selectedRegion?.name?.[language ?? "en"] ??
              state.regions.selectedRegion?.name?.["en"]
          }
        };
      },
      [language]
    )
  );

  const { form, setForm, onSwitchChange, onInputChange, onMultiSelectChange } = useForm<{
    online_consult?: boolean;
    countryId?: string;
    regionId?: string;
    areaIds?: string[];
    specialityIds?: string[];
    search?: string;
    doctor2?: string;
    home_visite?: boolean;
    searchDoctorName?: string;
  }>({
    countryId: query.sys_country_code3 ?? "",
    areaIds: query.area ?? [],
    regionId: query.region,
    specialityIds: query.specialty ?? [],
    home_visite: query.home_visite,
    online_consult: query.online_consult,
    searchDoctorName: query.search
  });

  const { specialitiesData, selectedSpecialities } = useAppSelector(
    React.useCallback(
      (state) => {
        return {
          specialitiesData: state.specialities.data
            .filter(({ name }) => {
              if (!form.search) return true;

              return Object.values(name)
                .map((n) => n.toLowerCase())
                .some((n) => new RegExp(form.search?.toLowerCase() ?? "").test(n));
            })
            .map(({ _id, name }) => ({
              _id,
              name: name?.[language ?? "en"] ?? name?.["en"]
            })),
          selectedSpecialities: state.specialities.selectedSpecialities?.map((spec) => {
            return {
              ...spec,
              name: spec.name?.[language ?? "en"] ?? spec.name?.["en"]
            };
          })
        };
      },
      [language, form.search]
    )
  );

  // initial effects
  React.useEffect(() => {
    dispatch(getSpecialitiesDispatch());
  }, []);

  // form & redux state effect from changing country
  React.useEffect(() => {
    if (!query.sys_country_code3 || form.countryId == query.sys_country_code3) {
      return;
    }

    dispatch(getRegionsDispatch(query.sys_country_code3));
    dispatch(setSelectedAreasDispatch(undefined));
    dispatch(setSelectedRegionDispatch(undefined));
    setForm((old: typeof form) => {
      return {
        ...old,
        countryId: query.sys_country_code3,
        regionId: "",
        areaIds: []
      };
    });
  }, [query.sys_country_code3]);

  //  home state
  const [specializeView, setSpecializeView] = React.useState<"none" | "block">("none");
  const [geographicView, setGeographicView] = React.useState<"none" | "block">("none");

  const [areasPage, setAreasPage] = React.useState<number>(0);
  const [specialitiesPage, setSpecialitesPage] = React.useState<number>(0);

  // redux state effect from changing form
  React.useEffect(() => {
    if (!form.specialityIds) {
      return;
    }

    dispatch(setSelectedSpecalitiesDispatch(form.specialityIds));
  }, [form.specialityIds]);

  React.useEffect(() => {
    if (!form.regionId) {
      return;
    }
    dispatch(setSelectedRegionDispatch(form.regionId));
    dispatch(getAreaDispatch(form.regionId));
    dispatch(setSelectedAreasDispatch(undefined));
  }, [form.regionId]);

  React.useEffect(() => {
    if (!form.areaIds) {
      return;
    }
    dispatch(setSelectedAreasDispatch(form.areaIds));
  }, [form.areaIds]);

  React.useEffect(() => {
    dispatch(setDoctorQueryDispatch({ search: form.searchDoctorName }));
  }, [form.searchDoctorName]);

  React.useEffect(() => {
    if (form.online_consult == undefined) {
      return;
    }
    dispatch(setDoctorQueryDispatch({ online_consult: form.online_consult }));
  }, [form.online_consult]);
  React.useEffect(() => {
    if (form.home_visite == undefined) {
      return;
    }
    dispatch(setDoctorQueryDispatch({ home_visite: form.home_visite }));
  }, [form.home_visite]);

  const reverseDirection = React.useCallback(() => {
    dispatch(
      setDirectionDispatch({
        direction: direction == "ltr" ? "rtl" : "ltr",
        language: direction == "ltr" ? "lang-ar" : "lang-en"
      })
    );
  }, [direction, language]);

  const resetSpecialties = React.useCallback(() => {
    dispatch(resetSelectedSpecialitiesDispatch());
    setForm((old: typeof form) => {
      return { ...old, specialityIds: [] };
    });
  }, []);
  const resetLocation = React.useCallback(() => {
    dispatch(resetSelectedAreasDispatch());
    dispatch(resetSelectedRegionDispatch());
    setForm((old: typeof form) => {
      return { ...old, areaIds: [], regionId: "" };
    });
  }, []);

  const submitForm = React.useCallback(
    (initQuery: typeof query = {}) => {
      const doctorQuery: typeof query = {
        ...query,
        ...initQuery,
        area: query.area ?? [],
        region: query.region ?? "",
        sys_country_code3: query.sys_country_code3 ?? "",
        language: language,
        online_consult: query.online_consult ?? false,
        pageNumber: 0,
        pageSize: 10,
        search: query.search ?? undefined,
        specialty: query.specialty
      };
      dispatch(setDoctorQueryDispatch(doctorQuery));
      dispatch(getDoctorsDispatch(doctorQuery));
      history.push("/search");
    },
    [query, language]
  );

  return {
    form,
    submitForm,
    resetSpecialties,
    resetLocation,

    query,

    onSwitchChange,
    onInputChange,
    onMultiSelectChange,

    reverseDirection,

    specializeView,
    setSpecializeView,
    geographicView,
    setGeographicView,

    countryData,
    regionData,
    areaData,
    specialitiesData,

    selectedCountry,
    selectedRegion,
    selectedAreas,
    selectedSpecialities,

    areasPage,
    specialitiesPage,

    setAreasPage,
    setSpecialitesPage,

    translate,
    language
  };
};
