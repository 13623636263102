import { AxiosResponse } from "axios";
import { DoctorLevelResponse } from "../interfaces";

import { axios } from "./configuration";

export const DoctorLevelApi = {
  getAll: async function (): Promise<AxiosResponse<DoctorLevelResponse>> {
    return axios.get(`/doctorlevel`);
  }
};
