import { RootUserAction, UserState } from "../../interfaces";
import { UserActionTypes } from "../../constants/actionTypes";
import { User } from "../../api/userApi";
import { ReviewResponse } from "../../api/doctorsApi";
import { Booking } from "../../api/bookingApi";

// reducer
const userState: UserState = {
  data: undefined,
  token: "",
  reviews: []
};

const setAreasData = (state: UserState, user: User): UserState => {
  return Object.assign({}, state, {
    data: user,
    token: user.token
  });
};

const setUserReviews = (state: UserState, reviews: ReviewResponse[]): UserState => {
  return Object.assign({}, state, { reviews: reviews });
};
const setUserSessions = (state: UserState, bookings: Booking[]): UserState => {
  return {
    ...state,
    data: { ...state.data, bookings: bookings }
  };
};
const UserReducer = (
  state: UserState = userState,
  { type, payload }: RootUserAction
): UserState => {
  switch (type) {
    case UserActionTypes.SET_USER_DATA: {
      return setAreasData(state, payload as User);
    }
    case UserActionTypes.SET_USER_REVIEWS: {
      return setUserReviews(state, payload as ReviewResponse[]);
    }
    case UserActionTypes.UPDATE_USER_REVIEW: {
      return setUserReviews(state, [payload as ReviewResponse]);
    }
    case UserActionTypes.SET_USER_SESSIONS: {
      return setUserSessions(state, payload as Booking[]);
    }
    default:
      return state;
  }
};

export default UserReducer;
