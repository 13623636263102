import React from "react";
import { useTranslation } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../redux";
import { setSelectedLanguage } from "../../redux/actions/languageActions";
import { setSelectedAreasDispatch } from "../../redux/middlewares/areaMiddlewares";
import {
  getCountriesDispatch,
  setSelectedCountryDispatch
} from "../../redux/middlewares/countryMiddlewares";
import { getLabelsDispatch } from "../../redux/middlewares/labeslMiddlewares";
import { setSelectedRegionDispatch } from "../../redux/middlewares/regionMiddlewares";
import { userLogoutDispatch } from "../../redux/middlewares/userMiddlewares";

const useNavigation = () => {
  const dispatch = useAppDispatch();
  const { language, translate, languagesData, selectedLanguage } = useTranslation();
  const { countriesData, selectedCountry } = useAppSelector(
    React.useCallback(
      (state) => {
        return {
          countriesData: state.countries.data.map((c) => ({
            ...c,
            name: c.name?.[language ?? "en"] ?? c.name?.["en"]
          })),
          selectedCountry: {
            ...state.countries.selectedCountry,
            name:
              state.countries.selectedCountry?.name?.[language ?? "en"] ??
              state.countries.selectedCountry?.name?.["en"]
          }
        };
      },
      [language]
    )
  );
  const user = useAppSelector((state) => state.user);
  React.useEffect(() => {
    dispatch(getCountriesDispatch());
  }, []);
  const onCountrySelect = React.useCallback((countryId: string) => {
    dispatch(setSelectedCountryDispatch(countryId));
    dispatch(setSelectedAreasDispatch(undefined));
    dispatch(setSelectedRegionDispatch(undefined));
  }, []);

  const onLanguageSelect = React.useCallback((languageId: string) => {
    dispatch(setSelectedLanguage(languageId));
  }, []);

  const onLogout = React.useCallback(() => {
    dispatch(userLogoutDispatch(user.token ?? ""));
  }, [user.token]);
  React.useEffect(() => {
    dispatch(getLabelsDispatch("directoryHome", selectedLanguage?.language_code ?? "en"));
  }, [selectedLanguage?.language_code]);

  return {
    language,
    translate,
    countriesData,
    selectedCountry,
    onCountrySelect,
    onLanguageSelect,
    languagesData,
    selectedLanguage,
    onLogout,
    user
  };
};

export default useNavigation;
