import * as React from "react";

function BreadcrumbHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="2.4rem"
      height="2.4rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 20a1 1 0 01-1 1H4a1 1 0 01-1-1V9.314a1 1 0 01.38-.785l8-6.311a1 1 0 011.24 0l8 6.31a1 1 0 01.38.786V20zM7 12a5 5 0 1010 0h-2a3 3 0 01-6 0H7z"
        fill="#006FBB"
      />
    </svg>
  );
}

const MemoBreadcrumbHome = React.memo(BreadcrumbHome);
export default MemoBreadcrumbHome;
