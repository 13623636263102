import { InsuranceState, InsuranceResponse, RootInsuranceAction } from "../../interfaces";
import { InsuranceActionTypes } from "../../constants/actionTypes";

// reducer
const insuranceState: InsuranceState = {
  data: [],
  total: 0,
  selectedInsurances: []
};

const setInsuranceData = (
  state: InsuranceState,
  insurancesResponse: InsuranceResponse
): InsuranceState => {
  return Object.assign({}, state, {
    data: insurancesResponse.data,
    total: insurancesResponse.total
  });
};
const setSelectedInsurances = (state: InsuranceState, insuranceIds: string[]): InsuranceState => {
  return Object.assign({}, state, {
    selectedInsurances: state.data.filter((insurance) => insuranceIds.includes(insurance._id))
  });
};

const InsuranceReducer = (
  state: InsuranceState = insuranceState,
  { type, payload }: RootInsuranceAction
): InsuranceState => {
  switch (type) {
    case InsuranceActionTypes.SET_INSURANCE_DATA: {
      return setInsuranceData(state, payload as InsuranceResponse);
    }
    case InsuranceActionTypes.SET_SELECTED_INSURANCES: {
      return setSelectedInsurances(state, payload as string[]);
    }
    default:
      return state;
  }
};

export default InsuranceReducer;
