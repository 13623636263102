import * as React from "react";
import IconLabel from "../iconLabel";
import Stars, { StarsProps } from "../stars/stars";
import EarPlugIcon from "../../assets/ear-plug.svg";
import LocationIcon from "../../assets/location.svg";
import BusinessIcon from "../../assets/business.svg";
import TimeIcon from "../../assets/time.svg";
import FinanceIcon from "../../assets/finance.svg";
import DefaultAvatarIcon from "../../assets/profile-default.svg";
import CalendarList from "../calendarList";
import Svg from "../svg";
import { useHistory } from "react-router-dom";
import { clsx } from "../../utils/clsx";
export type CardDoctorProps = {
  loading?: boolean;
  language: string;
  doctorId: string;
  img?: string;
  title?: string;
  subtitle?: string;
  rating: StarsProps;
  allowBooking: boolean;
  bbtFlag: boolean;
  timeTable: { date: string; period?: { from: string; to: string }[] }[]; // UTC ISO date;
  details: {
    description: string;
    location: string;
    price: string;
    entrance: string;
    time: string;
  };
  patientId: string;
  onCardClick: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CardDoctor: React.FC<CardDoctorProps> = (props: CardDoctorProps) => {
  const history = useHistory();
  const _renderImg = () => {
    if (props.img) {
      return <img src={props.img} alt="doctorImg" />;
    }
    return (
      <div
        style={{
          width: "17.1rem",
          height: "20.5rem",
          backgroundColor: "var(--color-gray-7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "1rem"
        }}
      >
        <Svg color="var(--color-gray-6)" height="8.2rem" width="8.2rem" icon={DefaultAvatarIcon} />
      </div>
    );
  };
  return (
    <div className={"card-doctor"}>
      <div className="card-doctor__img" style={{ cursor: "pointer" }} onClick={props.onCardClick}>
        {_renderImg()}
      </div>
      <div className="card-doctor__data">
        <div
          className="card-doctor__data__title"
          style={{ cursor: "pointer" }}
          onClick={props.onCardClick}
        >
          {props.title}
        </div>
        <div className="card-doctor__data__sub-title">{props.subtitle}</div>
        <div className="card-doctor__data__rating">
          <Stars
            noOfStars={props.rating.noOfStars}
            selectAction={() => {
              history.push(`details/${props.doctorId}`);
            }}
          />
        </div>
        <div className="card-doctor__data__details-item">
          <IconLabel
            svg={{
              color: "var(--color-blue-1)",
              height: "2.4rem",
              width: "2.4rem",
              icon: EarPlugIcon
            }}
            text={props.details.description}
            textColor="var(--color-blue-1)"
            overrideTextClasses="typo--m typo--regular"
          />
        </div>
        <div className="card-doctor__data__details-item">
          <IconLabel
            svg={{
              color: "var(--color-blue-1)",
              height: "2.4rem",
              width: "2.4rem",
              icon: LocationIcon
            }}
            text={props.details.location}
            textColor="var(--color-gray-3)"
            overrideTextClasses="typo--m typo--regular"
          />
        </div>
        <div className="card-doctor__data__details-item">
          <IconLabel
            svg={{
              color: "var(--color-blue-1)",
              height: "2.4rem",
              width: "2.4rem",
              icon: FinanceIcon
            }}
            text={props.details.price}
            textColor="var(--color-gray-3)"
            overrideTextClasses="typo--m typo--regular"
          />
        </div>
        <div className="card-doctor__data__details-item">
          <IconLabel
            svg={{
              color: "var(--color-blue-1)",
              height: "2.4rem",
              width: "2.4rem",
              icon: BusinessIcon
            }}
            text={props.details.entrance}
            textColor="var(--color-gray-3)"
            overrideTextClasses="typo--m typo--regular"
          />
        </div>
        <div className="card-doctor__data__details-item">
          <IconLabel
            svg={{
              color: "var(--color-blue-1)",
              height: "2.4rem",
              width: "2.4rem",
              icon: TimeIcon
            }}
            text={props.details.time}
            textColor="var(--color-status-correct)"
            overrideTextClasses="typo--m typo--regular"
          />
        </div>
      </div>
      <div className={clsx("card-doctor__calendar", !!props.loading && "loading")}>
        <CalendarList
          // inListing={true}
          patientId={props.patientId}
          allowBooking={props.allowBooking}
          bbtflag={props.bbtFlag}
          selectedDates={[props.timeTable.length ? props.timeTable[0].date : ""]}
          currentLanguage={props.language}
          onChange={props.onChange}
          doctorId={props.doctorId}
          data={props.timeTable}
        />
      </div>
    </div>
  );
};

export default CardDoctor;
