import * as React from "react";
import CopyRight from "../../components/copyRight";

import LogoIcon from "../../assets/logo-2.svg";
import CallIcon from "../../assets/call.svg";

import Input from "../../components/input";
import Button from "../../components/button";
import Svg from "../../components/svg";

import useForgetPassword from "./useForgetPassowrd";

const ForgetPassword: React.FC<any> = () => {
  const { onInputChange, translate, errors, history, submit } = useForgetPassword();

  return (
    <div className="forget-password">
      <div className="forget-password__logo">
        <img src={LogoIcon} alt="Clinio" />
      </div>
      <div className="forget-password__form">
        <div className="forget-password__form__main-title">
          {translate("forget_password__form__main_title")}
        </div>
        <div className="forget-password__form__sub-title">
          {translate("forget_password__form__sub_title")}
        </div>
        <div className="forget-password__form__input-container">
          <div className="forget-password__form__input-container__item">
            <Input.InputNormal
              name="email"
              autoComplete="email"
              onchange={onInputChange}
              placeholder={translate("login__form__email__placeholder")}
            />
          </div>
          <div className="forget-password__form__input-container__error">{errors.email}</div>
        </div>
        <div className="forget-password__form__actions">
          <div className="forget-password__form__actions__button">
            <Button.ButtonWide action={submit} text={translate("forget_password__from__send")} />
          </div>
          <div className="forget-password__form__actions__button">
            <Button.ButtonWide
              action={() => {
                history.push("/login");
              }}
              overrideStyles={{
                backgroundColor: "var(--color-gray-8)",
                color: "var(--color-blue-1)",
                border: "1px solid var(--color-blue-1)"
              }}
              text={translate("forget_password__from__back")}
            />
          </div>
        </div>
        <div className="forget-password__form__main-catch-phrase">
          {translate("home__search__main_catch_phrase")}
        </div>
        <div className="forget-password__form__sub-catch-phrase">
          {translate("home__search__sub_catch_phrase")}
        </div>
        <div className="forget-password__form__contact">
          {translate("clinio__contact_number")}
          <div className="forget-password__form__contact__icon">
            <Svg width="2rem" height="2rem" color="var(--color-gray-3)" icon={CallIcon} />
          </div>
        </div>
      </div>
      <div className="forget-password__copy-right">
        <CopyRight />
      </div>
    </div>
  );
};

export default ForgetPassword;
