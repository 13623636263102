import * as React from "react";
import { clsx } from "../../utils/clsx";
import ArrowLeftIcon from "../../assets/arrow-left.svg";
import Button from "../button";

type DropdownBodyProps = {
  afterSelect: React.Dispatch<React.SetStateAction<boolean>>;
};
type DropdownProps = {
  DropdownButton: () => JSX.Element;
  DropdownBody: (props: DropdownBodyProps) => JSX.Element;
  setRef?: any;
};

const Dropdown: React.FC<DropdownProps> = ({
  DropdownBody,
  DropdownButton,
  ...props
}: DropdownProps) => {
  const [visable, setVisable] = React.useState<boolean>(false);
  return (
    <div className="dropdown">
      <div
        ref={props.setRef}
        className="dropdown__button"
        onClick={() => {
          setVisable((old) => !old);
        }}
      >
        {<DropdownButton />}
      </div>
      <div className={clsx("dropdown__body", visable && "visable")}>
        {<DropdownBody afterSelect={setVisable} />}
      </div>
    </div>
  );
};

export type DropdownRadioProps = {
  formName: string;
  setRef?: any;
  data: { name: string; _id?: string; value: any }[];
  selectedItem?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  afterSelect?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DropdownRadio: React.FC<DropdownRadioProps> = (props: DropdownRadioProps) => {
  const _renderItems = (afterSelect?: React.Dispatch<React.SetStateAction<boolean>>) => {
    return props.data.map((v, i) => {
      return (
        <React.Fragment key={v._id ?? i}>
          <div className="dropdown-radio__item radio">
            <label htmlFor={v._id} className="dropdown-radio__item__label radio__label">
              {v.name}
            </label>
            <div className="dropdown-radio__item__radio">
              <input
                type="radio"
                id={v._id}
                name={props.formName}
                value={v.name}
                className=" radio__input-radio"
                onChange={() => {}}
                checked={props.selectedItem == v._id}
                onClick={(event) => {
                  const ev = {
                    ...event,
                    target: { ...event.target, name: props.formName, value: v }
                  };
                  props.onChange(ev as any);
                  if (afterSelect) {
                    afterSelect(false);
                  }
                }}
              />
            </div>
          </div>
          <div className="divider"></div>
        </React.Fragment>
      );
    });
  };

  return (
    <Dropdown
      setRef={props.setRef}
      DropdownButton={() => (
        <Button.ButtonRounded
          svg={{
            width: "0.5rem",
            height: "0.8rem",
            color: "var(--color-gray-8)",
            icon: ArrowLeftIcon,
            customCss: { transform: "rotate(-90deg)" }
          }}
          overrideStyles={{ background: "var(--color-yellow-1)" }}
          action={() => {}}
        />
      )}
      DropdownBody={(prps: DropdownBodyProps) => (
        <div className="dropdown-radio">{_renderItems(prps.afterSelect)}</div>
      )}
    />
  );
};

export type DropdownLabelsProps = {
  formName: string;
  data: { name: string; _id?: string; value: any }[];
  selectedItem: string | undefined;
  overrideContentCss?: React.CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const DropdownLabels: React.FC<DropdownLabelsProps> = (props: DropdownLabelsProps) => {
  const _renderItems = (afterSelect: React.Dispatch<React.SetStateAction<boolean>>) => {
    return props.data.map((v, i) => {
      return (
        <React.Fragment key={v._id ?? i}>
          <div className={clsx("dropdown-label__item", props.selectedItem == v._id && "checked")}>
            <label htmlFor={v._id} className={"dropdown-label__item__label"}>
              {v.name}
              <input
                type="radio"
                hidden
                name={props.formName}
                onChange={() => {}}
                onClick={(event) => {
                  const ev = {
                    ...event,
                    target: { ...event.target, name: props.formName, value: v.value }
                  };

                  props.onChange(ev as any);
                  afterSelect(false);
                }}
                checked={props.selectedItem == v._id}
                value={v.name}
                id={v._id}
              />
            </label>
          </div>
        </React.Fragment>
      );
    });
  };
  return (
    <Dropdown
      DropdownButton={() => (
        <Button.ButtonRounded
          svg={{
            width: "0.5rem",
            height: "0.8rem",
            color: "var(--color-gray-8)",
            icon: ArrowLeftIcon,
            customCss: { transform: "rotate(-90deg)" }
          }}
          overrideStyles={{ background: "var(--color-yellow-1)" }}
          action={() => {}}
        />
      )}
      DropdownBody={(prps: DropdownBodyProps) => (
        <div style={props.overrideContentCss} className="dropdown-label">
          {_renderItems(prps.afterSelect)}
        </div>
      )}
    />
  );
};

export default { Dropdown, DropdownRadio, DropdownLabels };
