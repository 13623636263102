import * as React from "react";

import { clsx } from "../../utils/clsx";

export type ModalProps = {
  //
  ModalButton: () => JSX.Element;
  modalTitle: string;
  ModalBody: () => JSX.Element;
  overrideModalCSS?: React.CSSProperties;
};

const Modal: React.FC<ModalProps> = ({ ModalButton, ModalBody, ...props }: ModalProps) => {
  const [visable, setVisable] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <a
        className="modal-button"
        onClick={() => {
          setVisable(true);
        }}
      >
        <ModalButton />
      </a>
      {/* modal */}
      <div id="modal-container" className={clsx("modal-container", visable && "visable")}>
        <div className="modal " style={props.overrideModalCSS}>
          <h1 className="modal__header">{props.modalTitle}</h1>
          <div className="modal__body">
            <ModalBody />
          </div>
          {/* close modal */}
        </div>
        <a onClick={() => setVisable(false)} className="modal__background"></a>
      </div>
    </React.Fragment>
  );
};

export default Modal;
