export enum UserActionTypes {
  SET_USER_DATA = "SET_USER_DATA",
  SET_USER_REVIEWS = "SET_USER_REVIEWS",
  UPDATE_USER_REVIEW = "UPDATE_USER_REVIEW",
  SET_USER_SESSIONS = "SET_USER_SESSIONS"
}
export enum LoadingActionTypes {
  SET_LOADING = "SET_LOADING",
  RESET_LOADING = "RESET_LOADING"
}
export enum MetaActionTypes {
  SET_DIRECTION = "SET_DIRECTION"
}

export enum PaginationActionTypes {
  SET_DOCTORS_COUNT = "SET_DOCTORS_COUNT",
  SET_SPECIALTIES_COUNT = "SET_SPECIALTIES_COUNT"
}

export enum LanguagesActionTypes {
  SET_LANGUAGE_DATA = "SET_LANGUAGE_DATA",
  SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE"
}

export enum InsuranceActionTypes {
  SET_INSURANCE_DATA = "SET_INSURANCE_DATA",
  SET_SELECTED_INSURANCES = "SET_SELECTED_INSURANCES"
}

export enum DoctorActionTypes {
  SET_DOCTORS_DATA = "SET_DOCTORS_DATA",
  SET_SELECTED_DOCTOR_FROM_LIST = "SET_SELECTED_DOCTOR_FROM_LIST",
  SET_SELECTED_DOCTOR = "SET_SELECTED_DOCTOR",
  SET_DOCTORS_QUERY = "SET_DOCTORS_QUERY",
  SET_DOCTOR_SESSION = "SET_DOCTOR_SESSION",
  RESET_DOCTORS_DATA = "RESET_DOCTORS_DATA",
  SET_DOCTOR_REVIEWS = "SET_DOCTOR_REVIEWS",
  ADD_DOCTOR_REVIEW = "ADD_DOCTOR_REVIEW",
  UPDATE_DOCTOR_REVIEW = "UPDATE_DOCTOR_REVIEW"
}

export enum SpecialitiesActionTypes {
  SET_SPECIALTIES_DATA = "SET_SPECIALTIES_DATA",
  SET_SELECTED_SPECIALITIES = "SET_SELECTED_SPECIALITIES"
}
export enum LabelsActionTypes {
  SET_LABELS_DATA = "SET_LABELS_DATA"
}
export enum CountriesActionTypes {
  SET_COUNTRIES_DATA = "SET_COUNTRIES_DATA",
  SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY"
}
export enum RegionsActionTypes {
  SET_REGONS_DATA = "SET_REGONS_DATA",
  SET_SELECTED_REGION = "SET_SELECTED_REGION"
}
export enum AreaActionTypes {
  SET_AREA_DATA = "SET_AREA_DATA",
  SET_SELECTED_AREAS = "SET_SELECTED_AREAS"
}

export enum DoctorLevelActionTypes {
  SET_DOCTORLEVEL_DATA = "SET_DOCTORLEVEL_DATA",
  SET_SELECTED_DOCTORLEVELS = "SET_SELECTED_DOCTORLEVELS"
}

export enum SubSpecialityActionTypes {
  SET_SUBSPECIALITY_DATA = "SET_SUBSPECIALITY_DATA",
  SET_SELECTED_SUBSPECIALITIES = "SET_SELECTED_SUBSPECIALITIES"
}
