import { applyMiddleware, combineReducers, createStore } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

// presist & caching
import { persistReducer, PersistConfig } from "redux-persist";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// middlewares
import { createStateSyncMiddleware, Config, initStateWithPrevTab } from "redux-state-sync";
import thunk, { ThunkMiddleware } from "redux-thunk";

// reducers
import paginationReducer from "./reducers/paginationReducer";
import doctorsReducer from "./reducers/doctorsReducer";
import specialitiesReducer from "./reducers/specialitiesReducer";
import LabelsReducer from "./reducers/labelsReducer";
import CountriesReducer from "./reducers/countriesReducer";
import RegionsReducer from "./reducers/regionsReducer";
import AreasReducer from "./reducers/areasReducer";
import MetaReducer from "./reducers/metaReducer";
import LanguageReducer from "./reducers/languageReducer";

// whitelist sync tab
import {
  AreaActionTypes,
  CountriesActionTypes,
  MetaActionTypes,
  RegionsActionTypes,
  SpecialitiesActionTypes,
  // DoctorActionTypes,,
  LanguagesActionTypes,
  InsuranceActionTypes,
  DoctorLevelActionTypes,
  SubSpecialityActionTypes,
  UserActionTypes,
  LoadingActionTypes,
  DoctorActionTypes
} from "../constants/actionTypes";
// extentions
import { composeWithDevTools } from "redux-devtools-extension";
import persistStore from "redux-persist/lib/persistStore";
import InsuranceReducer from "./reducers/insurancesReducer";
import DoctorLevelReducer from "./reducers/doctorLevelReducer";
import SubSpecialityReducer from "./reducers/subSpecialityReducer";
import UserReducer from "./reducers/userReducer";
import LoadingReducer from "./reducers/loadingReducer";

// configs
// const doctorConfig = {
//   key: "root",
//   storage: storage,
//   stateReconciler: autoMergeLevel2,

//   whitelist: ["query"]
// };

const rootReducer = combineReducers({
  user: UserReducer,
  meta: MetaReducer,
  pagination: paginationReducer,
  doctors: doctorsReducer,
  // doctors: persistReducer(doctorConfig, doctorsReducer),
  loading: LoadingReducer,
  insurance: InsuranceReducer,
  specialities: specialitiesReducer,
  labels: LabelsReducer,
  countries: CountriesReducer,
  regions: RegionsReducer,
  areas: AreasReducer,
  language: LanguageReducer,
  doctorLevel: DoctorLevelReducer,
  subSpeciality: SubSpecialityReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "countries",
    "labels",
    "areas",
    "regions",
    "specialities",
    "language",
    "insurance",
    "user",
    "doctors",
    "loading"
  ],
  transforms: [createWhitelistFilter("doctors", ["query"])]
};
const syncConfig: Config = {
  whitelist: [
    MetaActionTypes.SET_DIRECTION,

    CountriesActionTypes.SET_COUNTRIES_DATA,
    CountriesActionTypes.SET_SELECTED_COUNTRY,

    SpecialitiesActionTypes.SET_SPECIALTIES_DATA,
    SpecialitiesActionTypes.SET_SELECTED_SPECIALITIES,

    AreaActionTypes.SET_AREA_DATA,
    AreaActionTypes.SET_SELECTED_AREAS,

    RegionsActionTypes.SET_REGONS_DATA,
    RegionsActionTypes.SET_SELECTED_REGION,

    InsuranceActionTypes.SET_INSURANCE_DATA,
    InsuranceActionTypes.SET_SELECTED_INSURANCES,

    LanguagesActionTypes.SET_LANGUAGE_DATA,
    LanguagesActionTypes.SET_SELECTED_LANGUAGE,

    DoctorLevelActionTypes.SET_DOCTORLEVEL_DATA,
    DoctorLevelActionTypes.SET_SELECTED_DOCTORLEVELS,

    SubSpecialityActionTypes.SET_SUBSPECIALITY_DATA,
    SubSpecialityActionTypes.SET_SELECTED_SUBSPECIALITIES,

    LoadingActionTypes.RESET_LOADING,
    LoadingActionTypes.RESET_LOADING,

    UserActionTypes.SET_USER_DATA
  ]
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// inits
export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware, createStateSyncMiddleware(syncConfig)),
    sentryReduxEnhancer
  )
);

initStateWithPrevTab(store);
// types
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const presistor = persistStore(store);

export default { store, useAppDispatch, useAppSelector, presistor };
