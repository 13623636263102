import { RootMetaAction, MetaState } from "../../interfaces";
import { MetaActionTypes } from "../../constants/actionTypes";

// reducer
const metaState: MetaState = {
  direction: "ltr",
  language: "lang-en"
};

const setMetaData = (
  state: MetaState,
  direction: typeof metaState.direction,
  language: typeof metaState.language
): MetaState => {
  return Object.assign({}, state, { direction: direction, language: language });
};

const MetaReducer = (
  state: MetaState = metaState,
  { type, payload }: RootMetaAction
): MetaState => {
  switch (type) {
    case MetaActionTypes.SET_DIRECTION: {
      return setMetaData(state, payload.direction, payload.language);
    }
    default:
      return state;
  }
};
export default MetaReducer;
