import * as React from "react";
import Calendar from "../calendar";
import Button from "../button";

import ArrowLeftIcon from "../../assets/arrow-left.svg";
import useForm from "../../hooks/useForm";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getDoctorSessionsDispatch } from "../../redux/middlewares/doctorsMiddlewares";
import { useTranslation } from "../../hooks";
import { debugVal } from "../../helpers/debugwrapper";

export type CalendarListProps = {
  // inListing: boolean;
  doctorId: string;
  patientId: string;
  allowBooking: boolean;
  bbtflag: boolean;
  data: { date: string; period?: { from: string; to: string }[] }[]; // UTC ISO date
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedDates: string[];
  currentLanguage: string;
};

const CalendarList: React.FC<CalendarListProps> = (props: CalendarListProps) => {
  const calendarListRef = React.useRef<HTMLDivElement>(null);
  const { translate } = useTranslation();
  const [scrollPage, setScrollPage] = React.useState<number>(0);
  const { form, onInputChange } = useForm<{ [key: string]: string }>();
  const { selectedLanguage } = useAppSelector((state) => state.language);
  const [isAction, setIsAction] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userBookings = useAppSelector((state) => state.user?.data?.bookings);
  React.useEffect(() => {
    // calendarListRef.current?.scrollTo({
    //   left:
    //     ((!selectedLanguage?.r2l ? 1 : -1) * calendarListRef.current?.clientWidth ?? 0) *
    //     scrollPage,
    //   behavior: "smooth"
    // });

    if (!props.doctorId || (scrollPage == 0 && !isAction)) {
      return;
    }
    dispatch(getDoctorSessionsDispatch(props.doctorId, { pageSize: 4, pageNumber: scrollPage }));
  }, [
    scrollPage,
    props.doctorId,
    isAction
    // calendarListRef,
    //  selectedLanguage?.r2l
  ]);

  React.useEffect(() => {
    props.onChange({ target: { name: props.doctorId, value: form as any } } as any);
  }, [form, props.onChange, props.doctorId]);

  const getDates = React.useCallback(() => {
    // en-uk doesnt change
    // requires low level itteration get unique values

    const dayData = Array(
      ...new Set(props.data.map((d) => new Date(d.date).toLocaleDateString("en-uk")))
    );

    const timeTable = dayData.map((day) =>
      // en-uk doesnt change
      props.data.filter((d) => new Date(d.date).toLocaleDateString("en-uk") == day)
    );
    return { dayData, timeTable };
  }, [props.data]);

  const _renderItems = React.useMemo(() => {
    const { timeTable, dayData } = getDates();
    if (!timeTable.length && props.allowBooking) {
      return (
        <div
          className="calendar-list__list__item"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "25.5rem",
            width: "54.0rem"
          }}
        >
          {/* No Bookings avilable */}
          {translate("no_bookings")}
        </div>
      );
    }
    return timeTable.map((list, i) => {
      const doctorBookings =
        userBookings?.filter(
          (b: any) =>
            b?.doctor == props?.doctorId &&
            b?.session?.session_no == (list[0] as any)?.session?.session_no
        ) ?? [];

      return (
        <div className="calendar-list__list__item" key={list.length ? list[0].date : i}>
          <Calendar
            data={list}
            patientId={props.patientId}
            doctorId={props.doctorId}
            bbtFlag={props.bbtflag}
            allowBooking={props.allowBooking}
            alreadyBooked={doctorBookings.length}
            selectedDate={form[dayData[i]] ?? ""}
            header={list.length ? list[0].date : "UNKNOWN"}
            name={dayData[i]}
            onChange={onInputChange}
          />
        </div>
      );
    });
  }, [props.data, props.doctorId, getDates, form, props.bbtflag, props.allowBooking, userBookings]);

  const _renderPagination = React.useMemo(() => {
    const { timeTable } = getDates();
    const leftAction = () => {
      setIsAction(true);
      setScrollPage((old) => {
        // if ((old + 1) * 4 >= timeTable.length - 1) {
        if (!timeTable.length) {
          return old;
        } else {
          return old + 1;
        }
      });
    };
    const rightAction = () => {
      setIsAction(true);
      setScrollPage((old) => {
        // if ((old - 1) * 4 < 0) {

        if (old - 1 < 0) {
          return old;
        } else {
          return old - 1;
        }
      });
    };

    let left = (
      <div
        className="calendar-list__pagination__direction calendar-list__pagination__direction--left"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button.ButtonRounded
          // disabled={
          //   selectedLanguage?.r2l
          //     ? (scrollPage - 1) * 4 < 0
          //     : (scrollPage + 1) * (4 + 1) >= timeTable.length - 1
          // }
          disabled={selectedLanguage?.r2l ? scrollPage == 0 : !timeTable.length}
          action={selectedLanguage?.r2l ? rightAction : leftAction}
          overrideStyles={selectedLanguage?.r2l ? { transform: "rotate(180deg)" } : {}}
          svg={{
            color: "var(--color-gray-8)",
            height: "0.9rem",
            width: "0.5rem",
            icon: ArrowLeftIcon,
            reversable: true
          }}
        />
      </div>
    );
    let right = (
      <div className="calendar-list__pagination__direction calendar-list__pagination__direction--right">
        <Button.ButtonRounded
          // disabled={
          //   !selectedLanguage?.r2l
          //     ? (scrollPage - 1) * 4 < 0
          //     : (scrollPage + 1) * 4 >= timeTable.length
          // }
          disabled={!selectedLanguage?.r2l ? scrollPage == 0 : !timeTable.length}
          action={selectedLanguage?.r2l ? leftAction : rightAction}
          overrideStyles={!selectedLanguage?.r2l ? { transform: "rotate(180deg)" } : {}}
          svg={{
            color: "var(--color-gray-8)",
            height: "0.9rem",
            width: "0.5rem",
            icon: ArrowLeftIcon,
            reversable: true
          }}
        />
      </div>
    );

    if (selectedLanguage?.r2l) {
      let temp = right;
      right = left;
      left = temp;
    }

    return (
      <div className="calendar-list__pagination">
        {left}
        {right}
      </div>
    );
  }, [getDates, scrollPage, selectedLanguage?.r2l, translate]);

  return (
    <div className="calendar-list-container">
      <div className="calendar-list" ref={calendarListRef}>
        <div className="calendar-list__list">{_renderItems}</div>
      </div>
      {_renderPagination}
    </div>
  );
};

export default CalendarList;
