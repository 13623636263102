import * as React from "react";
import verticalLineIcon from "../../assets/vertical-line.svg";
import searchIcon from "../../assets/search.svg";
import { SvgProps } from "../svg/svg";
import Svg from "../svg";
import { clsx } from "../../utils/clsx";

export type InputProps = {
  name: string;
  value?: string | number;
  defaultValue?: string;
  type?: string;
  pre?: JSX.Element;
  adornment?: JSX.Element;
  autoComplete?: string;
  onfocus?: () => void;
  onblur?: () => void;
  // eslint-disable-next-line no-unused-vars
  onchange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  overrideStyles?: React.CSSProperties;
  overrideInputStyles?: React.CSSProperties;
  inputDirection?: "rtl" | "ltr";
};

const InputNormal: React.FC<InputProps> = (props: InputProps) => {
  const _renderPre = React.useMemo(() => {
    if (!props.pre) {
      return <div style={{ display: "flex", marginRight: "0.4rem", marginLeft: "0.4rem" }}></div>;
    }
    return <div className="input__pre">{props.pre}</div>;
  }, [props.pre]);

  const _renderAdornment = React.useMemo(() => {
    if (!props.adornment) {
      return <div style={{ width: "2.4rem", height: "2.4rem" }}></div>;
      // return <React.Fragment></React.Fragment>;
    }
    return <div className="input__adornment">{props.adornment}</div>;
  }, [props.adornment]);

  return (
    <div className="input" style={props.overrideStyles ?? {}}>
      {_renderPre}
      <input
        dir={props.inputDirection}
        style={props.overrideInputStyles}
        autoComplete={props.autoComplete}
        name={props.name}
        onBlur={props.onblur}
        onFocus={props.onfocus}
        onchange={props.onchange}
        defaultValue={props.defaultValue}
        className="input__text"
        type={props.type}
        value={props.value}
        placeholder={props.placeholder ?? ""}
      />
      {_renderAdornment}
    </div>
  );
};

export type InputPreProps = {
  //
  svg?: SvgProps;
  text?: string;
  overrideStyles?: React.CSSProperties;
};
const InputPre: React.FC<InputPreProps> = (props: InputPreProps) => {
  const _renderIcon = React.useMemo(() => {
    if (!props.svg) {
      return <React.Fragment></React.Fragment>;
    }
    // return <img src={props.svg} alt="" />;
    return (
      <Svg
        color={props.svg.color}
        height={props.svg.height}
        icon={props.svg.icon}
        width={props.svg.width}
      />
    );
  }, [props.svg]);
  const _renderText = React.useMemo(() => {
    if (!props.text) {
      return <React.Fragment></React.Fragment>;
    }
    return <div className="input-pre__text">{props.text}</div>;
  }, [props.text]);

  return (
    <div className="input-pre" style={props.overrideStyles ?? {}}>
      {_renderIcon}
      {_renderText}
      <img src={verticalLineIcon} alt="" />
    </div>
  );
};

export type InputSwitchProps = {
  // id: string;
  name: string;
  // eslint-disable-next-line no-unused-vars
  onchange: (event: React.ChangeEvent<HTMLButtonElement>) => void;
  // overrideStyles?: React.CSSProperties;
  checked?: boolean;
  disabled?: boolean;
};

const InputSwitch: React.FC<InputSwitchProps> = (props: InputSwitchProps) => {
  return (
    <button
      type="button"
      name={props.name}
      role="switch"
      value={props.checked as any}
      aria-checked={props.checked ?? false}
      disabled={props.disabled}
      className={clsx("switch", !!props.checked && "switch--checked")}
      onClick={props.onchange as any}
    />
  );
};

export type InputSearchProps = {
  name: string;
  // eslint-disable-next-line no-unused-vars
  onchange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  overrideStyles?: React.CSSProperties;
  value?: string;
};

const InputSearch: React.FC<InputSearchProps> = (props: InputSearchProps) => {
  return (
    <InputNormal
      pre={<img className="mirror" src={searchIcon} alt={"search"} />}
      placeholder={props.placeholder}
      value={props.value}
      onchange={props.onchange}
      overrideStyles={props.overrideStyles ?? {}}
      name={props.name}
    />
  );
};

export default { InputNormal, InputPre, InputSwitch, InputSearch };
