import React from "react";

export interface CheckBoxProps {
  id: string;
  name: string;
  title: string;
  value?: string;
  checked?: boolean;
  onchange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  return (
    <div className="checkbox">
      <input
        id={props.id}
        type="checkbox"
        defaultValue={props.value}
        defaultChecked={props.checked}
        className="checkbox__input"
        name={props.name}
        onChange={props.onchange}
      />
      <label className="checkbox__label" htmlFor={props.id}>
        {props.title}
      </label>
    </div>
  );
};

export default CheckBox;
