import * as React from "react";
import Svg from "../svg";
import { SvgProps } from "../svg/svg";

export type CardIconProps = {
  svg?: SvgProps;
  title: string;
  titleColor?: string;
  subTitle?: string;
  subTitileColor?: string;
};

const CardIcon: React.FC<CardIconProps> = (props: CardIconProps) => {
  const svgIcon = React.useMemo(() => {
    if (!props.svg) {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <Svg
        color={props.svg.color}
        height={props.svg.height}
        width={props.svg.width}
        icon={props.svg.icon}
      />
    );
  }, [props.svg]);
  if (!props.subTitle) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div className="card-icon">
      <div className="card-icon__icon">
        {/* <img src={props.icon} alt={props.title} /> */}
        {svgIcon}
      </div>
      <div className="card-icon__title" style={{ color: props.titleColor }}>
        {props.title}
      </div>
      <div className="card-icon__sub-title" style={{ color: props.subTitileColor }}>
        {props.subTitle}
      </div>
    </div>
  );
};

export default CardIcon;
