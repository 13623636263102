import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../hooks";
import { useAppDispatch } from "../redux";

import { getLanguagesDispatch } from "../redux/middlewares/languageMiddleware";
import { setDirectionDispatch } from "../redux/middlewares/metaMiddlewares";

const useApp = () => {
  const dispatch = useAppDispatch();
  const { languagesData, direction, language } = useTranslation();

  React.useEffect(() => {
    dispatch(getLanguagesDispatch());
  }, []);

  React.useEffect(() => {
    if (!direction || !language) {
      dispatch(setDirectionDispatch({ direction: "ltr", language: "lang-en" }));
    }
  }, [direction, language]);

  return { languagesData, direction, language };
};
export default useApp;
