import { AppDispatch } from "..";
import { pageLabeslApi } from "../../api/pageLabelsApi";
import { setLabelsData } from "../actions/labelsActions";
import { setLoading } from "../actions/loadingActions";

export const getLabelsDispatch = (pageCode: string, language: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ labels: true }));
    const response = await pageLabeslApi.getAll(pageCode, language).catch((r) => r);
    dispatch(setLoading({ labels: false }));
    if (response.status !== 200) {
      // Handle error case
      // You might want to dispatch an error action here
      return;
    }
    dispatch(setLabelsData(language, response.data.model));
  };
};
