import { DoctorLevelActionTypes } from "../../constants/actionTypes";
import { DoctorLevel, DoctorLevelState, RootDoctorLevelAction } from "../../interfaces";

// reducer
const doctorLevelState: DoctorLevelState = {
  data: [],
  selectedDoctorLevels: undefined
};

const setDoctorLevelData = (
  state: DoctorLevelState,
  doctorLevelList: DoctorLevel[]
): DoctorLevelState => {
  return Object.assign({}, state, { data: doctorLevelList });
};

const setSelectedDoctorLevels = (
  state: DoctorLevelState,
  doctorLevelIds: string[]
): DoctorLevelState => {
  return Object.assign({}, state, {
    setSelectedDoctorLevels: state.data.filter((docLevel) => doctorLevelIds.includes(docLevel._id))
  });
};

const DoctorLevelReducer = (
  state: DoctorLevelState = doctorLevelState,
  { type, payload }: RootDoctorLevelAction
): DoctorLevelState => {
  switch (type) {
    case DoctorLevelActionTypes.SET_DOCTORLEVEL_DATA: {
      return setDoctorLevelData(state, payload as DoctorLevel[]);
    }
    case DoctorLevelActionTypes.SET_SELECTED_DOCTORLEVELS: {
      return setSelectedDoctorLevels(state, payload as string[]);
    }
    default:
      return state;
  }
};
export default DoctorLevelReducer;
