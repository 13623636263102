import React from "react";
import { useTranslation } from "../../hooks";
import useForm from "../../hooks/useForm";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
  getDoctorLevelsDispatch
  // setSelectedDoctorLevelsDispatch
} from "../../redux/middlewares/doctorLevelMiddlewares";

import {
  getDoctorsDispatch,
  setDoctorQueryDispatch,
  setSelectedDoctorDispatch
} from "../../redux/middlewares/doctorsMiddlewares";
import // getInsurancesDispatch,
// setSelectedInsurancesDispatch
"../../redux/middlewares/insuranceMiddlewares";
import { getInsurancesDispatch } from "../../redux/middlewares/insuranceMiddlewares";
import {
  getSubSpecialitiesDispatch,
  setSelectedSubSpecialitiesDispatch
} from "../../redux/middlewares/subSpecialitiesMiddlewares";
import { useHome } from "../home/useHome";

const useSearch = () => {
  const { language, selectedLanguage, translate } = useTranslation();

  const dispatch = useAppDispatch();
  const { query, doctorsData, totalNumOfDoctors } = useAppSelector((state) => {
    return {
      doctorsData: state.doctors.data.map((v: any) => ({
        ...v,
        name: v.name?.[language ?? "en"] ?? v.name?.["en"],
        address: v.address?.[language ?? "en"] ?? v.address?.["en"],
        workingHours: v.workingHours?.[language ?? "en"] ?? v.workingHours?.["en"],
        description: v.description?.[language ?? "en"] ?? v.description?.["en"]
      })),
      query: state.doctors.query,

      totalNumOfDoctors: state.doctors.total
    };
  });
  const { selectedSpecialities } = useAppSelector((state) => state.specialities);
  const { subSpecialityData, selectedSubSpecialities } = useAppSelector((state) => {
    return {
      subSpecialityData: state.subSpeciality.data.map((sub) => {
        return {
          id: sub._id,
          name: sub.name?.[language ?? "en"] ?? sub.name?.["en"],
          value: sub._id
        };
      }),
      selectedSubSpecialities: state.subSpeciality.selectedSubSpecialities
    };
  });

  const selectedCountry = useAppSelector((state) => state.countries.selectedCountry);

  const { insurancesData, selectedInsurances } = useAppSelector((state) => {
    return {
      insurancesData: state.insurance.data.map((insurance) => {
        return {
          id: insurance._id,
          name: insurance.name?.[language ?? "en"] ?? insurance.name?.["en"],
          value: insurance._id
        };
      }),
      selectedInsurances: state.insurance.selectedInsurances
    };
  });

  const { doctorLevelData, selectedDoctorLevels } = useAppSelector((state) => {
    return {
      doctorLevelData: state.doctorLevel.data.map((dl) => {
        return { id: dl._id, name: dl.name?.[language ?? "en"] ?? dl.name?.["en"], value: dl._id };
      }),
      selectedDoctorLevels: state.doctorLevel.selectedDoctorLevels
    };
  });

  const { form: DoctorListForm, onInputChange } = useForm();
  const { submitForm } = useHome();
  const {
    form: filterForm,
    onMultiSelectChange: onMultiSelectFilterChange,
    onInputChange: onInputFilterChange
  } = useForm<{
    insurances: string[];
    doctorLevel: string[];
    minRange?: string;
    maxRange?: string;
    gender?: string;
    activePrice?: string[];
    subSpecialities: string[];
  }>({
    insurances: query.insurance ?? [],
    minRange: query.min_consult_fees ?? "",
    maxRange: query.max_consult_fees ?? "",
    gender: query.gender,
    activePrice: ["minRange", "maxRange"],
    doctorLevel: query.doctorLevel ?? [],
    subSpecialities: query.subSpecialties ?? []
  });

  const onCardDoctorClick = React.useCallback((pageNumber: number, doctorId?: string) => {
    dispatch(setSelectedDoctorDispatch(pageNumber, doctorId ?? ""));
  }, []);

  const setCurrentPage = React.useCallback(
    (page: number) => {
      dispatch(setDoctorQueryDispatch({ pageNumber: page - 1 }));
      dispatch(getDoctorsDispatch({ ...query, pageNumber: page - 1 }));
    },
    [query]
  );
  React.useEffect(() => {
    submitForm({
      gender: filterForm.gender,
      doctorLevel: filterForm.doctorLevel,
      max_consult_fees: filterForm.maxRange,
      min_consult_fees: filterForm.minRange,
      subSpecialties: filterForm.subSpecialities,
      insurance: filterForm.insurances
    });
  }, [filterForm]);

  // county effects
  React.useEffect(() => {
    dispatch(getInsurancesDispatch(selectedCountry?._id ?? ""));
    submitForm();
  }, [selectedCountry?._id]);

  // doctor level effects
  React.useEffect(() => {
    dispatch(getDoctorLevelsDispatch());
  }, []);

  // range effects
  React.useEffect(() => {
    dispatch(
      setDoctorQueryDispatch({
        max_consult_fees: filterForm.activePrice?.includes("maxRange")
          ? ""
          : filterForm.maxRange ?? "2000",

        min_consult_fees: filterForm.activePrice?.includes("minRange")
          ? ""
          : filterForm.minRange ?? "0"
      })
    );
    // dispatch(getDoctorsDispatch(query));
  }, [filterForm.activePrice, filterForm.maxRange, filterForm.minRange]);

  // subspeciality
  React.useEffect(() => {
    dispatch(getSubSpecialitiesDispatch(selectedSpecialities?.map((s) => s._id ?? "") ?? []));
    dispatch(setSelectedSubSpecialitiesDispatch([]));
  }, [selectedSpecialities]);

  React.useEffect(() => {
    dispatch(setSelectedSubSpecialitiesDispatch(filterForm.subSpecialities ?? []));
  }, [filterForm.subSpecialities]);

  return {
    DoctorListForm,

    insurancesData,
    selectedInsurances,

    onInputChange,
    onCardDoctorClick,

    doctorsData,
    query,

    setCurrentPage,
    totalNumOfDoctors,
    language,
    selectedLanguage,

    selectedCountry,

    filterForm,
    onMultiSelectFilterChange,
    onInputFilterChange,

    selectedDoctorLevels,
    doctorLevelData,

    subSpecialityData,
    selectedSubSpecialities,

    translate
  };
};

export default useSearch;
