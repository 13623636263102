import React, { FC, Fragment, useCallback, useMemo } from "react";

import LeftIcon from "../../assets/left.svg";
import RightIcon from "../../assets/right.svg";
import { useTranslation } from "../../hooks";
import { useAppSelector } from "../../redux";
import Svg from "../svg";

export interface PaginationProps {
  pagesCount: number;
  currentPage: number;
  language: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (page: number) => void;
}

export interface PageProps {
  page: number;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Page: FC<PageProps> = ({ page, selected, onClick }) => (
  <li
    onClick={onClick}
    className={`pagination__item${selected ? " pagination__item--selected" : ""} `}
  >
    <a>{page.toLocaleString()}</a>
  </li>
);

const Pagination: FC<PaginationProps> = ({ language, pagesCount, currentPage, onChange }) => {
  const { selectedLanguage } = useAppSelector((state) => state.language);
  const { translate } = useTranslation();
  const _onChange = useCallback(
    (page: number) => () => {
      debugger;
      document.getElementById("root")?.scrollIntoView({ behavior: "smooth" });
      if (page <= 0) {
        onChange?.(1);
        return;
      }
      if (page >= pagesCount) {
        onChange?.(pagesCount);
        return;
      }
      onChange?.(page);
    },
    [onChange]
  );

  const renderLeft = useMemo(() => {
    if (currentPage > 2 && ![pagesCount - 1, pagesCount].includes(currentPage)) {
      return (
        <Fragment>
          <Page onClick={_onChange(currentPage - 1)} page={currentPage - 1} />
          <Page onClick={_onChange(currentPage)} page={currentPage} selected />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Page onClick={_onChange(1)} page={1} selected={currentPage === 1} />
        {pagesCount >= 2 && <Page onClick={_onChange(2)} page={2} selected={currentPage === 2} />}
      </Fragment>
    );
  }, [currentPage, pagesCount]);

  const renderMid = useMemo(() => {
    if (pagesCount > 4) {
      return <li className="pagination__item">...</li>;
    }
    return null;
  }, [pagesCount]);

  const renderRight = useMemo(
    () =>
      pagesCount > 2 && (
        <Fragment>
          <Page
            page={pagesCount - 1}
            selected={currentPage === pagesCount - 1}
            onClick={_onChange(pagesCount - 1)}
          />
          <Page
            page={pagesCount}
            selected={currentPage === pagesCount}
            onClick={_onChange(pagesCount)}
          />
        </Fragment>
      ),
    [pagesCount, currentPage]
  );

  if (!(1 <= currentPage && currentPage <= pagesCount)) {
    return null;
  }

  return (
    <ul className="pagination">
      <li onClick={_onChange(1)} className="pagination__button">
        <a>{translate("pagination__first")}</a>
      </li>
      <div className="pagination__content">
        <div
          className="pagination__icon "
          onClick={_onChange(!selectedLanguage?.r2l ? currentPage + 1 : currentPage - 1)}
        >
          {/* <img src={LeftIcon} alt="arrow left icon" /> */}
          <Svg
            icon={RightIcon}
            // customCss={{ transform: "rotate(0deg)" }}
            width="0.701rem"
            height="1.198rem"
            color="var(--color-blue-1)"
            reversable
          />
        </div>
        {renderLeft}
        {renderMid}
        {renderRight}
        <div
          className="pagination__icon"
          onClick={_onChange(selectedLanguage?.r2l ? currentPage - 1 : currentPage + 1)}
        >
          <Svg
            icon={LeftIcon}
            // customCss={{ transform: "rotate(-180deg)" }}
            width="0.701rem"
            height="1.198rem"
            color="var(--color-blue-1)"
            reversable
          />
        </div>
      </div>
      <li onClick={_onChange(pagesCount)} className="pagination__button">
        <a>{translate("pagination__last")}</a>
      </li>
    </ul>
  );
};

export default Pagination;
