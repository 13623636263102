import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks";
import useErrors from "../../hooks/useErrors";
import useForm from "../../hooks/useForm";
import isEmail from "validator/es/lib/isEmail";
const useForgetPassword = () => {
  const { translate } = useTranslation();
  const { form, onInputChange } = useForm<{ email: string }>({
    email: ""
  });
  const { errors, assignError, clearError } = useErrors<typeof form>({
    email: ""
  });
  const history = useHistory();

  const validateOnEmail = React.useCallback(() => {
    if (!isEmail(form.email)) {
      assignError("email", translate("login__form__email_error"));
      return false;
    }
    clearError("email");
    return true;
  }, [form.email]);

  const submit = React.useCallback(() => {
    if ([validateOnEmail()].every((v) => v == true)) {
    }
  }, [validateOnEmail]);

  return {
    form,
    onInputChange,
    translate,
    errors,
    history,
    submit
  };
};
export default useForgetPassword;
