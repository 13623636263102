import { SubSpecialityActionTypes } from "../../constants/actionTypes";
import { RootSubSpecialitiesAction, SubSpeciality, SubSpecialityState } from "../../interfaces";

// reducer
const subSpecialityState: SubSpecialityState = {
  data: [],
  selectedSubSpecialities: []
};

const setSubSpecialityData = (
  state: SubSpecialityState,
  subSpecialityList: SubSpeciality[]
): SubSpecialityState => {
  return Object.assign({}, state, {
    data: subSpecialityList
  });
};
const setSelectedSubSpeciality = (
  state: SubSpecialityState,
  subSpecialityIds: string[]
): SubSpecialityState => {
  return Object.assign({}, state, {
    selectedSubSpecialities: state.data.filter((subSpeciality) =>
      subSpecialityIds.includes(subSpeciality._id ?? "")
    )
  });
};

const SubSpecialityReducer = (
  state: SubSpecialityState = subSpecialityState,
  { type, payload }: RootSubSpecialitiesAction
): SubSpecialityState => {
  switch (type) {
    case SubSpecialityActionTypes.SET_SUBSPECIALITY_DATA: {
      return setSubSpecialityData(state, payload as SubSpeciality[]);
    }
    case SubSpecialityActionTypes.SET_SELECTED_SUBSPECIALITIES: {
      return setSelectedSubSpeciality(state, payload as string[]);
    }
    default:
      return state;
  }
};

export default SubSpecialityReducer;
