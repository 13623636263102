import React from "react";

const useForm = <T>(initial?: T) => {
  const [form, setForm] = React.useState<T>(initial ?? ({} as T));

  const onSwitchChange = React.useCallback((event: React.ChangeEvent<HTMLButtonElement>) => {
    setForm((old: typeof form) => {
      //@ts-ignore
      return { ...old, [event.target.name]: !old[event.target.name] };
    });
  }, []);

  const onInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((old: typeof form) => {
      return { ...old, [event.target.name]: event.target.value };
    });
  }, []);

  const onMultiSelectChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((old: typeof form) => {
      // debugger;
      const field: string[] | undefined = (old as any)[event.target.name];
      // there is no field or there is but its empty then add 1st item to list
      if (!field || !field?.length) {
        return { ...old, [event.target.name]: [event.target.value] };
      }
      // item is not exits then toggle on
      if (!field.includes(event.target.value)) {
        return { ...old, [event.target.name]: [...field, event.target.value] };
      }
      // toggle off selected item if exits
      const toggleList = field.filter((f) => f != event.target.value);
      return { ...old, [event.target.name]: toggleList };
    });
  }, []);

  return {
    form,
    setForm,
    onSwitchChange,
    onInputChange,
    onMultiSelectChange
  };
};

export default useForm;
