import { SubSpecialityActionTypes } from "../../constants/actionTypes";
import { SubSpeciality } from "../../interfaces";

export const setSubSpecialityData = (subSpecialityList: SubSpeciality[]) => {
  return {
    type: SubSpecialityActionTypes.SET_SUBSPECIALITY_DATA,
    payload: subSpecialityList
  };
};

export const setSelectedSubSpecialities = (subSpecialityIds?: string[]) => {
  return {
    type: SubSpecialityActionTypes.SET_SELECTED_SUBSPECIALITIES,
    payload: subSpecialityIds ?? []
  };
};
