import * as React from "react";
import Button from "../button";
import ArrowLeftIcon from "../../assets/arrow-left.svg";

export type SelectListProps = {
  name: string;
  selectedIds?: string[];
  page?: number;
  onPageChange?: any;
  onChange: any;
  columns: number;
  rows: number;
  data: { _id?: string; name: string }[];
  header?: string;
  overrideContainerStyle?: React.CSSProperties;
  overrideButtonStyle?: React.CSSProperties;
  overrideButtonSelectClasses?: { selected?: string; default?: string };
  // eslint-disable-next-line no-unused-vars
  overrideHeaders?: (value: any, classes: string) => JSX.Element;
};

const SelectList: React.FC<SelectListProps> = (props: SelectListProps) => {
  // const [selectedItem, setSelectedItem] = React.useState<string>("");

  const _renderItems = React.useCallback(
    (data: { _id?: string; name: string }[]) => {
      return data.map((d, i) => {
        return (
          <div className="select-list__list__column__item" key={d?._id}>
            <Button.ButtonSelect
              action={props.onChange}
              classes={props.overrideButtonSelectClasses?.default}
              overrideStyles={props.overrideButtonStyle}
              value={d?._id ?? i.toString()}
              name={props.name}
              text={d.name}
              overrideSelectClass={props.overrideButtonSelectClasses?.selected}
              selected={props.selectedIds?.includes(d._id ?? "")}
            />
          </div>
        );
      });
    },
    [
      props.selectedIds,
      props.overrideButtonStyle,
      props.overrideButtonSelectClasses,
      props.onChange
    ]
  );

  const _renderList = React.useMemo(() => {
    let list: JSX.Element[] = [];
    if (!props.data.length) {
      return (
        <div className="select-list__list">
          <div className="select-list__list__column"></div>
          <div className="select-list__list__column"></div>
        </div>
      );
    }
    for (
      let i = (props.page ?? 0) * (props.rows * props.columns);
      i < props.data.length;
      i += props.rows
    ) {
      let data = props.data.slice(i, i + props.rows);
      if (data.length && list.length < props.columns) {
        list.push(
          <div className="select-list__list__column" key={i}>
            {_renderItems(data)}
          </div>
        );
      }
    }
    if (list.length < props.columns) {
      Array(props.columns - list.length)
        .fill(0)
        .forEach((v, i) =>
          list.push(<div className="select-list__list__column" key={`unused-${i}`}></div>)
        );
    }
    return <div className="select-list__list">{list}</div>;
  }, [props.data, props.rows, props.columns, _renderItems]);

  const _renderHeader = React.useMemo(() => {
    if (props.overrideHeaders) {
      return props.overrideHeaders(props.header ?? "", `select-list__header`);
    }
    if (props.header) {
      return <div className="select-list__header">{props.header}</div>;
    }
    return <React.Fragment></React.Fragment>;
  }, [props.overrideHeaders, props.header]);

  return (
    <div style={props.overrideContainerStyle} className="select-list">
      {_renderHeader}
      {_renderList}
      {props.data.length !== 0 &&
        props.onPageChange &&
        props.data.length > props.rows * props.columns && (
          <div className="select-list__pagination">
            <div className="select-list__pagination__direction">
              <Button.ButtonRounded
                action={() => {
                  if (props.page !== undefined && props.page > 0) {
                    props.onPageChange(props.page - 1);
                  }
                }}
                disabled={props.page === undefined || props.page <= 0}
                overrideStyles={{ transform: "rotate(180deg)" }}
                svg={{
                  color: "var(--color-gray-8)",
                  height: "0.9rem",
                  width: "0.5rem",
                  icon: ArrowLeftIcon,
                  reversable: true
                }}
              />
            </div>
            <div className="select-list__pagination__direction">
              <Button.ButtonRounded
                action={() => {
                  if (
                    props.page !== undefined &&
                    props.rows * props.columns * (props.page + 1) < props.data.length
                  ) {
                    props.onPageChange(props.page + 1);
                  }
                }}
                disabled={
                  !(
                    props.page !== undefined &&
                    props.rows * props.columns * (props.page + 1) < props.data.length
                  )
                }
                svg={{
                  color: "var(--color-gray-8)",
                  height: "0.9rem",
                  width: "0.5rem",
                  icon: ArrowLeftIcon,
                  reversable: true
                }}
              />
            </div>
          </div>
        )}
      {!props.data.length && <h1 className="">NO DATA</h1>}
    </div>
  );
};

export default SelectList;
