import React, { useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useAppSelector } from "./../redux/index";

const useTranslation = () => {
  const { data, selectedLanguage } = useAppSelector(useCallback((state) => state.language, []));
  const { data: labelsData } = useAppSelector(useCallback((state) => state.labels, []));

  React.useEffect(() => {
    document.body.dir = selectedLanguage?.r2l ? "rtl" : "ltr";
  }, [selectedLanguage?.r2l]);

  const translate = React.useCallback(
    (key: string) => {
      const languageCode = selectedLanguage?.language_code ?? "en";
      if (
        !labelsData ||
        !labelsData[languageCode] ||
        Object.keys(labelsData[languageCode]).length === 0
      ) {
        console.log("No translations available for", languageCode, labelsData);
        return "NO LOCALIZE";
      }

      if (labelsData[languageCode][key]) {
        return labelsData[languageCode][key].replaceAll("\n", "\0");
      } else {
        console.log(`${key} is not translated in ${languageCode}`);
        Sentry.captureMessage(`${key} is not translated in ${languageCode}`);
        return "NOT TRANSLATED";
      }
    },
    [labelsData, selectedLanguage]
  );

  return {
    translate,
    language: selectedLanguage?.language_code ?? "en",
    languagesData: data,
    direction: selectedLanguage?.r2l ? "rtl" : "ltr",
    selectedLanguage
  };
};

export default useTranslation;
