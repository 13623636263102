import * as React from "react";
import Svg from "../svg";
import { SvgProps } from "../svg/svg";

export type IconLabelProps = {
  //
  text: string;
  svg: SvgProps;
  textColor?: string;
  overrideTextClasses?: string;
};

const IconLabel: React.FC<IconLabelProps> = (props: IconLabelProps) => {
  if (!props.text) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div className="icon-label">
      <div className="icon-label__icon">
        <Svg
          color={props.svg.color}
          width={props.svg.width}
          height={props.svg.height}
          icon={props.svg.icon}
        />
      </div>
      <div
        className={`icon-label__text typo--truncate ${props.overrideTextClasses ?? ""}`}
        style={{ color: props.textColor }}
      >
        {props.text}
      </div>
    </div>
  );
};

export default IconLabel;
