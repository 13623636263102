import { InsuranceActionTypes } from "../../constants/actionTypes";
import { InsuranceResponse } from "../../interfaces";

export const setInsuranceData = (insuranceResponse: InsuranceResponse) => {
  return {
    type: InsuranceActionTypes.SET_INSURANCE_DATA,
    payload: insuranceResponse
  };
};

export const setSelectedInsurances = (insuranceIds?: string[]) => {
  return {
    type: InsuranceActionTypes.SET_SELECTED_INSURANCES,
    payload: insuranceIds
  };
};

export const resetSelectedInsurances = () => {
  return {
    type: InsuranceActionTypes.SET_SELECTED_INSURANCES,
    payload: []
  };
};
