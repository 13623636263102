import { AppDispatch } from "..";
import { SpecialitiesApi } from "../../api/specialitiesApi";
import { setDoctorQuery } from "../actions/doctorActions";
import { setLoading } from "../actions/loadingActions";
import {
  resetSelectedSpecialities,
  setSelectedSpecalities,
  setSpecialityData
} from "../actions/specialityActions";

export const getSpecialitiesDispatch = (favorite?: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading({ specialities: true }));
    try {
      const response = await SpecialitiesApi.getAll(favorite);
      dispatch(setLoading({ specialities: false }));
      if (response.status === 200) {
        console.log("API Response:", response.data);
        dispatch(setSpecialityData(response.data.model ?? []));
      } else {
        console.error("API Error:", response.status, response.data);
      }
    } catch (error) {
      console.error("API Call Error:", error);
      dispatch(setLoading({ specialities: false }));
    }
  };
};

export const setSelectedSpecalitiesDispatch = (specialityIds?: string[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setSelectedSpecalities(specialityIds));
    dispatch(setDoctorQuery({ specialty: specialityIds }));
  };
};

export const resetSelectedSpecialitiesDispatch = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedSpecialities());
    dispatch(setDoctorQuery({ specialty: [] }));
  };
};
