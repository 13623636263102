import * as React from "react";
import CalendarList, { CalendarListProps } from "../calendarList/calendarList";
import CardIcon from "../cardIcon";
import { CardIconProps } from "../cardIcon/cardIcon";
import IconLabel, { IconLabelProps } from "../iconLabel/iconLabel";

export type ReservationProps = {
  title: string;
  details: {
    type: CardIconProps;
    location: IconLabelProps;
    time: CardIconProps;
    price: CardIconProps;
    entrance: IconLabelProps;
  };
  locationPhrase?: string;
  enrancePhrase?: string;
  date: {
    title: string;
    calendar?: CalendarListProps;
  };
};

const Reservation: React.FC<ReservationProps> = (props: ReservationProps) => {
  return (
    <div className="reservation">
      <div className="reservation__title">{props.title}</div>
      <div className="reservation__cards">
        <CardIcon
          title={props.details.type.title}
          subTitle={props.details.type.subTitle}
          svg={props.details.type.svg}
        />
        <CardIcon
          title={props.details.price.title}
          subTitle={props.details.price.subTitle}
          svg={props.details.price.svg}
        />
        <CardIcon
          title={props.details.time.title}
          subTitle={props.details.time.subTitle}
          svg={props.details.time.svg}
          titleColor="var(--color-status-correct)"
          subTitileColor="var(--color-status-correct)"
        />
      </div>
      <div className="divider"></div>
      <div className="reservation__location">
        <IconLabel
          svg={props.details.location.svg}
          text={props.details.location.text}
          textColor="var(--color-gray-3)"
          overrideTextClasses=".typo--m"
        />
        <div className="reservation__location__catch-phrase">{props.locationPhrase}</div>
      </div>
      <div className="divider"></div>
      <div className="reservation__entrance">
        <IconLabel
          svg={props.details.entrance.svg}
          text={props.details.entrance.text}
          textColor="var(--color-gray-3)"
          overrideTextClasses=".typo--m"
        />
        <div className="reservation__entrance__catch-phrase">{props.enrancePhrase}</div>
      </div>
      <div className="reservation__date">
        <div className="reservation__date__title">{props.date.title}</div>
        <div className="reservation__date__content">
          {!props.date.calendar ? (
            <></>
          ) : (
            <CalendarList
              patientId={props.date.calendar.patientId}
              allowBooking={props.date.calendar.allowBooking}
              bbtflag={props.date.calendar.bbtflag}
              selectedDates={props.date.calendar.selectedDates}
              currentLanguage={props.date.calendar.currentLanguage}
              onChange={props.date.calendar.onChange}
              doctorId={props.date.calendar.doctorId}
              data={props.date.calendar.data}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reservation;
